@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.popup__wrapper {
  max-width: 550px;

  @include media-breakpoint-down(xs) {
    width: 90%;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.popup__title {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 20px;
}

.popup__body {
  margin: 0 0 25px;
}

.popup__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media-breakpoint-down(xs) {
    display: block;
  }
}

.popup__button {
  font-size: 14px;
  margin-right: 30px;

  @include media-breakpoint-down(xs) {
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
