@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: $z-modal;
  width: 85%;

  @include media-breakpoint-up(sm) {
    width: auto;
  }

  &-transaction-details {
    .modal__body {
      background-color: $transaction-modal-body-bg;
    }

    @include media-breakpoint-up(sm) {
      width: 100%;
      max-width: 715px;
    }
  }
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $modal-overlay;
  z-index: $z-modal;
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
}

.modal--animated {
  animation: fadeIn ease 0.2s;
}

.modal__body {
  border-radius: 10px;
  background-color: $modal-body-bg;
  position: relative;
  margin: 0;
  max-width: 90vw;
  max-height: 90vh;
  font-size: 13px;
  line-height: 1.3;
  width: 100%;
}

.modal__body--minimal {
  padding: 28px 30px 29px;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: $c-scrollbar-thumb $c-scrollbar-track-bg;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: $c-scrollbar-track-bg;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $c-scrollbar-thumb;
  }

  @include media-breakpoint-down(xs) {
    padding-right: 15px;
    padding-left: 15px;

    > div {
      width: 100%;
    }
  }
}

.modal__body--base {
  display: block !important;
  padding: 34px 35px;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-breakpoint-down(xs) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.modal__body--confirmation {
  display: flex;
  justify-content: center;
  max-width: 368px;
  width: calc(100vw - 40px);
  border-radius: 2px !important;

  > div > button[aria-label='Close'] {
    top: 26px;
    right: 27px;
  }
}

.modal__logo {
  position: absolute;
  top: 42px;
  left: 25px;
  z-index: $z-modal-logo;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.modal__container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.modal--hidden {
  display: none;

  &.modal--animated {
    animation: fadeOut ease forwards 0.2s;
  }
}

.modal__form-close-button {
  top: 35px;
  right: 45px;

  @include media-breakpoint-down(md) {
    top: 32px;
    right: 24px;
  }

  @include media-breakpoint-down(xs) {
    top: 22px;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -100;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
