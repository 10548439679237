@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.form__wrapper {
  padding: 43px 30px;

  @include media-breakpoint-up(sm) {
    padding: 43px 64px;
  }
}

.title {
  border-bottom: 1px solid $c-form-border;
  margin-bottom: 30px;
  padding-bottom: 13px;
  position: relative;
  bottom: 4px;
}

.field__wrapper {
  width: 100%;
  max-width: 100%;

  @include media-breakpoint-up(sm) {
    width: 370px;
  }
}

.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.error-message {
  font-size: 10px;
  font-weight: 300;
  color: $c-error-field;
  font-family: $font-complementary;
}

.close-button {
  top: 41px;
  right: 30px;

  @include media-breakpoint-up(sm) {
    right: 63px;
  }
}

.button__wrapper {
  margin-top: 45px;
  display: flex;
  justify-content: flex-end;
}

.save-button {
  margin-left: 5px;
  position: relative;
  left: 7px;
}
