@import 'src/shared/styles/variables';

.loading-bar {
  height: 8px;
  width: 256px;
  border-radius: 12px;
  background-color: $c-gray;
}

.loading-bar__filler {
  background-color: $c-blue;
  border-radius: 12px;
  height: 100%;
  transition: width $t-default;
}
