@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.marketing {
  position: relative;
  border-radius: 10px;
  background-color: $c-light-blue;
  padding: 25px 32px 20px;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  width: 100%;
}

.marketing-image {
  max-height: 54px;
  padding-right: 20px;
}

.marketing__text-block {
  .marketing-title {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    max-width: 150px;
    margin-bottom: 4px;
    margin-top: 0;
  }

  .marketing-button {
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    padding-left: 1px;
    text-decoration: none;
    text-align: left;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      background: #005fda;
      height: 1px;
      width: 100%;
      left: 1px;
    }

    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
    }
  }
}
