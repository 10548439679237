@import 'src/shared/styles/variables';
@import '~bootstrap/scss/bootstrap-grid';

.layout {
  margin: 58px 6px 0;

  @include media-breakpoint-down(sm) {
    margin-top: 38px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 32px;
  }
}

.layout__content {
  width: 100%;
}

.edit-form__modal {
  position: relative;
  padding: 34px 64px 41px 39px;
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    padding: 40px 20px;
  }

  @include media-breakpoint-down(xs) {
    padding: 20px;
    max-width: 300px;
  }
}

.form__title {
  position: absolute;
  top: 0;
  left: 7px;
  line-height: 1;
}

.form-title__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.form-title__icon {
  fill: $accent-color;
  margin-right: 7px;
}

.field__value {
  color: $accent-color;
}

.field__content-subtitle {
  position: absolute;
  font-size: 12px;
  max-width: 175px;
  transform: translateY(5px);
  line-height: 1.2;

  @include media-breakpoint-down(xs) {
    font-size: 10px;
  }
}

.field__content-subtitle--description {
  transform: translateY(7px);
}

.field-change-logo__wrapper {
  align-items: center;
  padding-bottom: 4px;
  display: grid;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 15px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    grid-template-rows: 1fr 50px;
    grid-row-gap: 10px;
  }

  @include media-breakpoint-down(xs) {
    grid-template-rows: 1fr 30px;
    grid-row-gap: 15px;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 273px;
    grid-column-gap: 20px;
  }
}

.field__input {
  background-color: transparent;
  height: 35px;
  max-width: 273px;

  @include media-breakpoint-down(xs) {
    height: 30px;
    font-size: 11px;
  }
}

.input__wrapper {
  margin-left: 8px;

  @include media-breakpoint-down(xs) {
    margin-bottom: 13px;
  }
}

.horizontal__separator {
  margin-top: 26px;
  margin-bottom: 28px;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.button__panel {
  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: row;
  }

  @include media-breakpoint-up(sm) {
    float: right;
    position: relative;
    left: 7px;
  }
}

.save__button {
  width: 122px;
}

.cancel__button {
  margin-right: 20px;
  width: 97px;
}
