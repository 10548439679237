@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.header {
  z-index: $z-header;
  background: $c-white;
  min-height: 55px;
  position: sticky;
  top: 0;

  .header__wrapper {
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      bottom: -2px;
      content: '';
      position: absolute;
      border-bottom: 0.5px solid $c-header-border;
      width: calc(100% - 40px);

      @include media-breakpoint-up(lg) {
        width: 83%;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }

  .header__logo {
    transform: translateY(1px);

    @include media-breakpoint-down(sm) {
      margin-right: 30px;
    }
  }
}

.send-invoice-btn {
  transform: translate(1px, 1px);
  padding-right: 12px;
  padding-left: 12px;
  min-width: 122px;

  @include media-breakpoint-down(sm) {
    margin-top: 25px;
    margin-bottom: 20px;
  }
}
