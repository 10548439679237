@import 'src/shared/styles/variables';

.add-button {
  background-color: $c-light-gray;
  border-radius: 4px;
  display: flex;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  border: none;
  color: $c-blue;
  font-size: 13px;

  &:focus,
  &:hover {
    background-color: $c-light-gray !important;
    border-color: $c-light-gray !important;
    color: $c-blue !important;

    .add-icon {
      fill: $c-blue !important;
    }
  }
}

.add-icon-wrapper {
  margin-right: 17px;
}

.add-icon {
  fill: $c-blue;
  vertical-align: middle;
  transform: translateY(-2px);
}
