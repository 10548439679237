@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.action-item {
  border: 1px solid $c-gray-7;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background-color: $c-search-action-item-bg;
  border-radius: 10px;
  height: 100%;
  min-height: 152px;
  padding: 27px 0 30px;
  box-sizing: border-box;
  width: 175px;
  margin: 0 10px;
  font-family: $font;

  @include media-breakpoint-down(sm) {
    margin: 20px 0;
    width: 100%;
  }

  &:hover {
    .action-item__icon_wrapper {
      background-color: $c-yellow;
    }
  }
}

.action-item__icon_wrapper {
  @include size(45px);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $c-yellow;
  }

  > svg {
    fill: $c-black;
  }
}

.action-item__title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $c-search-action-item-title;
  margin: 15px 0 2px;
}

.action-item__clients {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: $c-gray-1;
}
