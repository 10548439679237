@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.search-input__wrapper {
  position: relative;
  width: 100%;
}

.field__wrapper {
  margin: 0;
  max-width: none;
}

.input {
  height: 47px;
  border-radius: 10px;
  padding: 8px 35px 8px 28px;
  transition: all $t-default;
  font-family: $font;
  font-weight: 600;

  &:not(:disabled):focus {
    border-color: $c-search-input-border-hover;
  }

  &:hover {
    background: $c-search-input-bg;
  }
}

.search-input__icon {
  width: 17px;
  height: 17px;
  fill: $c-search-input-icon;
  transition: fill $t-default;
  position: absolute;
  left: 33px;
  top: 50%;
  transform: translate(0, -50%);
}
