@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.balance-menu {
  position: relative;
  display: flex;
  flex-direction: row;
}

.balance-menu__button-primary {
  max-width: 89px;
  max-height: 30px;
  margin-right: 9px;
  margin-left: 2px;
}

.balance-menu__button-secondary {
  padding: 0 15px;
  max-height: 30px;
}
