@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.button__wrapper-line {
  display: flex;
  flex-direction: column;

  &:before {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: $c-gray-8;
  }
}

.button__wrapper {
  display: flex;
  align-items: center;
  margin-top: 28px;

  @include media-breakpoint-down(xs) {
    align-items: normal;
    flex-direction: column;
  }
}

.button__submit {
  width: 122px;
  height: 30px;

  @include media-breakpoint-down(xs) {
    margin-right: 5px;
    padding: 5px 20px;
  }
}

.button__cancel {
  margin-left: auto;
  width: 97px;
  height: 30px;
  margin-right: 20px;

  @include media-breakpoint-down(xs) {
    margin-right: 15px;
  }
}

.buttons__wrapper {
  margin-left: auto;
}

.text-error__wrapper {
  margin-left: auto;
  margin-right: 8px;

  @include media-breakpoint-down(xs) {
    margin-left: 0;
    margin-bottom: 5px;
  }
}

.error-text-c {
  color: $c-red;
}

.error-text-span {
  font-size: 15px;
  line-height: 19px;
}
