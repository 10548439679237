@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.billing-form {
  background: $c-add-bill-form-bg;
  border-radius: 10px;
  height: 100%;
  width: 90vw;
  max-width: 499px;
  display: flex;
  flex-direction: column;
  padding: 21px 63px 20px 44px;
  margin: 2px 0 0 0;
  border: 1px solid $c-block-border;

  @include media-breakpoint-down(md) {
    padding: 22px 30px;
  }

  @include media-breakpoint-down(sm) {
    padding: 22px 20px;
    margin-top: 25px;
  }

  @include media-breakpoint-down(xs) {
    padding: 22px 10px;
    margin-top: 0;
    width: 67vw;
    min-width: 310px;
  }
}

.billing-form__header {
  display: flex;
  align-items: center;
}

.icon {
  fill: $c-add-bill-form-icon;
  margin-right: 12px;
}
