@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

$rt-font-family: $font;
$rt-color-info: $c-light-blue;
$rt-color-success: $c-light-green-2;
$rt-color-warning: $c-light-yellow;
$rt-color-error: $c-light-red-2;

@import '~react-toastify/scss/main';

.toast-container {
  position: static !important;
  padding: 25px 0 0;
  width: 100%;
  transform: none !important;
  margin-bottom: 3px;

  &.public-toasts {
    position: fixed !important;
    top: 90px;
    left: 0;
    padding: 0 20px;

    @include media-breakpoint-up(lg) {
      padding-left: 8.5%;
      padding-right: 8.5%;
    }
  }
}

.Toastify__toast {
  min-height: 63px;
  width: 100%;
  border-radius: 4px;
  box-shadow: none;
  border: 0;
  padding: 10px 14px;
  overflow: inherit;
  margin-bottom: 10px;

  @include media-breakpoint-down(xs) {
    border-radius: 0;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}

#VERIFICATION_TOAST {
  cursor: auto;
}

.Toastify__toast-body {
  color: $c-toast;
  font-family: $font-complementary;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
}

.Toastify__close-button {
  display: none;
}
