@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.add-bill-review__container {
  background: $c-add-bill-form-bg;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  max-width: 499px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 55px 55px 41px 54px;
  margin: 0 auto;
  min-height: 495px;
  border: 1px solid $c-block-border;

  @include media-breakpoint-down(md) {
    padding: 40px 30px;
    margin-top: 25px;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 20px;
  }

  @include media-breakpoint-down(xs) {
    padding: 15px 10px;
    margin-top: 0;
  }
}

.header {
  font-size: 25px;
  line-height: 35px;
  color: $c-add-bill-review-header;
  margin-bottom: 6px;
}

.sub-header {
  font-size: 12px;
  color: $c-add-bill-review-header;
  margin: 0 0 45px 0;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 44px 0 29px;

  @include media-breakpoint-down(xs) {
    padding: 0 0 0 10px;
  }
}

.company-avatar {
  margin-right: 17px;

  @include media-breakpoint-down(sm) {
    margin-right: 14px;
  }
}

.company-avatar img {
  @include size(46px);

  @include media-breakpoint-down(sm) {
    @include size(38px);
  }
}

.company-avatar__icon {
  cursor: pointer;
}

.vendor {
  display: flex;
  align-items: center;
  padding-top: 2px;
}

.vendor-name {
  font-size: 16px;
  font-weight: 700;
  color: $c-add-bill-review-vendor-name;
  margin: 0 6px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.new-vendor {
  width: 75px;
  height: 18px;
  background: $c-add-bill-new-vendor-bg;
  border-radius: 4px;
  font-size: 10px;
  color: $c-add-bill-new-vendor;
  margin: 0;
  padding: 2px 7px 2px 9px;
  white-space: nowrap;
  transform: translateY(-1px);
}

.verify-icon {
  max-width: 20px;
  width: 100%;
  height: 20px;
}

.company-email {
  font-size: 12px;
  color: $c-gray-1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 15px;
  text-align: left;
}

.amount {
  font-size: 16px;
  color: $c-add-bill-review-vendor-amount;
  font-weight: 500;
  margin: 0 0 15px 10px;
  flex-shrink: 0;
  transform: translateY(6px);
  max-width: 107px;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-down(sm) {
    margin: 0;
    font-size: 14px;
  }

  &:first-letter {
    color: $c-green;
  }
}

.button__container {
  border-top: 0.5px solid $c-add-bill-review-border;
  padding-top: 21px;
  margin-top: auto;
}

.button-submit {
  width: 122px;
}
