@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.marketing__element {
  border-radius: 10px;
  background-color: $c-light-green-3;
  height: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0 33px;
  width: 100%;
}

.title {
  max-width: 200px;
  z-index: 2;
}

.marketing-bg {
  position: absolute;
  max-width: 300px;
  right: -2px;
  bottom: 22px;
  width: 67%;
}

.try__button {
  border: none !important;
  border-radius: 20px;
  background: $c-black-3;
  color: $c-white;
  font-size: 13px;
  height: 32px;
  line-height: 20px;
  width: 98px;
  position: absolute;
  left: 45px;
  top: 133px;
  z-index: 2;

  &:hover,
  &:active,
  &:focus {
    background: $c-black-3 !important;
    cursor: pointer;
  }
}
