@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.two-block__container {
  padding: 0 15px;
  position: relative;

  @include media-breakpoint-up(md) {
    padding: 0;
  }
}

.left-block {
  padding-top: 40px;
  padding-bottom: 52px;
}

.image {
  display: none;
  object-fit: contain;
  position: absolute;
  right: 0;
  max-width: calc(50.5vw - 74px);
  height: calc(100vh - 48px);
  max-height: 916px;
  top: calc(50% - 18px);
  transform: translate(-35px, calc(-50% - 8px));

  @include media-breakpoint-up(md) {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    right: -74px;
  }

  @include media-breakpoint-up(xl) {
    right: 0;
  }
}

.custom-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.verification-flow-bg {
  background-color: $c-light-gray-6;
}
