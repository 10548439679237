@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.modal__wrapper {
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 85vw;
  height: 85vh;
  padding: 30px 15px;
  overflow-y: auto;

  @include media-breakpoint-up(sm) {
    max-height: 600px;
    height: 85vh;
    min-height: inherit;
    width: 450px;
    padding: 30px 15px;
  }
}
