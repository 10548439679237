@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.date-input {
  color: $c-datepicker !important;

  &.datepicker--empty {
    color: $c-datepicker-placeholder !important;

    .date-input__icon {
      fill: $c-datepicker-placeholder;
    }
  }
}

.date-input__icon {
  @include size(15px);
  position: absolute;
  right: 18px;
  top: 10px;
  fill: $c-datepicker-active;
  transition: fill $t-default;
}
