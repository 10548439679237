@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.registration-company-modal__wrapper {
  max-width: 100%;

  @include media-breakpoint-up(sm) {
    width: 429px;
    min-height: 352px;
  }
}

.additional-modal__wrapper {
  min-height: 403px;
}

.registration-company-success__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    min-width: 429px;
    min-height: 351px;
  }
}

.success {
  &-img {
    margin-top: 65px;
    fill: $c-green;

    @include media-breakpoint-down(xs) {
      margin-top: 0;
    }
  }

  &-title {
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    margin-top: 30px;

    @include media-breakpoint-down(xs) {
      font-size: 28px;
    }
  }

  &-additional {
    font-family: $font-complementary;
    font-size: 16px;
    line-height: 20px;
    margin-top: 24px;
  }

  &-link {
    margin-top: 24px;
    width: 156px;
  }
}
