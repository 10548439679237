@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 44px;
}

.title {
  font-size: 34px;
  font-weight: 500;
  margin: 0 0 20px;
}

.subtitle {
  font-size: 15px;
  margin: 0 0 8px;
}

.loader {
  @include size(30px);

  div {
    &::before {
      height: 5px;
    }
  }
}
