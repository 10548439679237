@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.dropup {
  .dropdown-menu {
    top: auto !important;
    bottom: calc(100% + 10px) !important;
    left: 50% !important;
    margin-top: 0;
    margin-bottom: 6px;
    transform: translateX(-50%) !important;
    box-shadow: 0 4px 45px $c-light-gray-5;

    &::before {
      top: auto;
      bottom: -6px;
      z-index: -1;
    }
  }
}

.dropdown-button__menu {
  background: $dropdown-button-menu-bg;
  border-radius: 10px;
  padding: 11px 4px 4px;
  min-width: 129px;
  box-shadow: 0 4px 45px $c-light-gray-5;

  &::before {
    @include size(12px);
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    background: $dropdown-button-menu-bg;
    transform: translateX(-50%) rotate(45deg);
  }
}

.dropdown-button__menu-wrapper {
  overflow: hidden;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.dropdown-button__menu-item {
  display: block;
  border-radius: 2px;
  color: $dropdown-button;
  line-height: 15px;
  font-size: 12px;
  padding: 3px 10px 4px;
  font-family: $font-complementary;

  &:hover {
    color: $dropdown-button;
    text-decoration: none;
    background: $dropdown-button-active-item-bg;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.dropdown-button__menu-divider {
  height: 1px;
  margin-top: 2px;
  background: $dropdown-button-divider;
}

.dropdown-button__wrapper {
  outline: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    cursor: pointer;
  }
}

.dropdown-button__arrow-icon {
  margin-left: 5px;
  transform: translateY(1px);
}

.dropdown-button {
  position: relative;
}
