@import 'src/shared/styles/variables';

.container {
  margin-top: 26px;
}

.description {
  max-width: 232px;
}

.continue-button {
  display: block;
  margin-top: 24px;
  margin-left: auto;
}

.linked-accounts-header {
  display: flex;
  border-bottom: 1px solid $c-gray-8;
  margin-bottom: 24px;
  padding: 0 8px 17px;

  .text {
    margin-left: 4px;
  }
}

.accounts-container {
  max-height: 224px;
  margin: 0 -15px;
  padding: 0 15px;
  margin-bottom: 24px;
}
