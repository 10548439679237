@import 'src/shared/styles/variables';

.custom-label {
  color: $c-entry-input-label;
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: 500;
}

.custom-label__tip {
  color: $c-gray-12;
  width: 78%;
  margin-bottom: 1px;
  font-size: 13px;
}

.tfa-checkbox-field__wrapper {
  flex-direction: column;
  align-items: flex-start !important;
}

.tfa-checkbox__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tfa-checkbox__label {
  position: relative;
  width: 40px;
  height: 20px;
  margin-right: 15px;
  top: 3px;
}

.tfa-checkbox__label .tfa-checkbox__input {
  position: absolute;
}

.tfa-checkbox__track {
  position: absolute;
  cursor: pointer;
  top: 0;
  background-color: $c-checkbox-switcher-track;
  transition: 0.4s;
  border-radius: 34px;
  width: 34px;
  height: 14px;
  border: none;
  margin: 0;

  &::before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: -4px;
    bottom: -3px;
    background-color: $c-checkbox-switcher-knob;
    box-shadow: $c-checkbox-switcher-box-shadow;
    transition: 0.4s;
    border-radius: 50%;
  }
}

.tfa-checkbox__input:checked + .tfa-checkbox__track {
  background-color: $c-checkbox-switcher-track-checked;
  transition: 0.4s;
}

.tfa-checkbox__input:checked + .tfa-checkbox__track:before {
  transform: translateX(20px);
  background-color: $c-checkbox-switcher-knob-checked;
  transition: 0.4s;
}

.tfa-checkbox__input:checked + .tfa-checkbox__track:after {
  display: none;
}
