@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.settings-form__wrapper {
  width: 100%;
  border: 1px solid $c-dark-grey-1;
  background-color: $c-white;
  border-radius: 10px;
  padding: 26px 15px;

  @include media-breakpoint-up(md) {
    padding: 26px 44px;
  }
}
