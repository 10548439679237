@import 'src/shared/styles/variables';
@import '~bootstrap/scss/bootstrap-grid';

.invoice-review {
  min-height: 293px;
}

.invoice-review__field {
  grid-template-columns: 114px 1fr;

  &-value {
    font-size: 13px;
    color: $c-black;
    padding-top: 10px;
    word-wrap: break-word;
    max-width: 276px;

    @include media-breakpoint-down(xs) {
      max-width: 151px;
    }

    &--description {
      font-size: 13px;
      padding-top: 14px;
      word-wrap: break-word;
      max-width: 276px;

      @include media-breakpoint-down(xs) {
        max-width: 151px;
      }
    }
  }

  &-label--description {
    padding-top: 0;
  }

  &:not(:last-child) {
    margin-bottom: 36px;
  }
}

.invoice-review__button {
  padding: 0 17px;
}
