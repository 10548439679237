@import 'src/shared/styles/variables';

.breadcrumbs {
  background-color: transparent;
  border: none;
  position: absolute;
  top: -15px;
  display: flex;
  padding-left: 12px;
  box-sizing: border-box;
  align-items: center;
  font: inherit;

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
  }
}

.back-arrow {
  fill: $c-blue;
}

.text {
  color: $c-blue;
  margin-left: 5px;
}
