@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.mark-received-btn {
  width: 99px;
  padding-left: 0;
  padding-right: 0;
  transform: translateY(2px);
}

.mark-not-received-btn {
  width: 132px;

  &.not-active {
    color: $c-purple-2 !important;
    border-color: $c-light-gray-5 !important;
  }
}
