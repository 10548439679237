@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.fields-container {
  margin-bottom: 32px;
}

.form__label {
  text-align: center;
  margin-bottom: 16px;

  @include media-breakpoint-up(md) {
    text-align: start;
  }
}

.button__wrapper {
  display: flex;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
    margin: 0;
  }
}

.button {
  width: 255px;

  @include media-breakpoint-up(md) {
    width: fit-content;
  }
}
