@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.layout--basic {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > * {
    flex: 0 0 auto;
  }
}

.layout__main {
  flex: 1 1 auto;
  padding-top: 31px;
  padding-bottom: 20px;
  position: relative;
}

.scrollbar {
  left: 50%;
  transform: translateX(-50%);

  width: 320px;

  @include media-breakpoint-up(xs) {
    width: 510px;
  }

  @include media-breakpoint-up(md) {
    width: 690px;
  }

  @include media-breakpoint-up(lg) {
    width: 930px;
  }

  @include media-breakpoint-up(xl) {
    width: 1028px;
  }
}

.scrollbar__shadow-top {
  top: 207px;

  @include media-breakpoint-up(xs) {
    top: 190px;
  }
}
