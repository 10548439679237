@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.button__wrapper-line {
  display: flex;
  flex-direction: column;

  &:before {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: $c-gray-8;
  }
}

.button__wrapper {
  display: flex;
  align-items: center;
  margin-top: 28px;
}

.button__submit {
  width: 97px;
  height: 30px;

  @include media-breakpoint-down(xs) {
    margin-right: 5px;
    padding: 5px 20px;
  }
}

.button__cancel {
  margin-left: auto;
  min-width: auto;
  padding: 0;
  border: none;
  margin-right: 24px;

  @include media-breakpoint-down(xs) {
    margin-right: 15px;
  }
}

.buttons__wrapper {
  margin-left: auto;
}
