@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.empty-invoice-history__wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;

  .empty-invoice-history__inner {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 16px;
  }

  .empty-invoice-history__icon {
    margin-left: 6px;
    margin-right: 21px;
    flex-grow: 0;
    flex-shrink: 0;

    @include media-breakpoint-up(sm) {
      margin-left: 13px;
      margin-right: 29px;
    }
  }

  .empty-invoice-history__title,
  .empty-invoice-history__description {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    max-width: 200px;
  }

  .empty-invoice-history__title {
    font-weight: 500;
  }
}
