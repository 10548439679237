@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.stripe-form__light-theme {
  input {
    background-color: $bg-color;
  }
}

.stripe-form__row {
  display: flex;
  justify-content: space-between;
}

.stripe-form__row-indent--default {
  margin-bottom: 10px;
}

.stripe-form__row-indent--extended {
  margin-bottom: 28px;
}

.stripe-form__field {
  margin-bottom: 0;
  min-width: auto;
  width: 100%;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.stripe-form__label {
  color: $text-color;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  margin: 0 0 5px 0;
  display: block;

  &:after {
    color: $label-required-color;
    content: '*';
    display: inline-block;
    font-size: 12px;
    padding-left: 3px;
    transform: translateY(-2px);
  }
}

.stripe-form__controls {
  margin-top: 41px;
}

.stripe-form__btn {
  margin-right: 12px;
}

.stripe-input {
  background-color: $bg-color;
  border: 1px solid $input-border;
  border-radius: 2px;
  color: $input-color;
  font-family: inherit;
  font-size: 13px;
  padding: 0 11px;
  width: 100%;
  height: 35px;
  transition: background-color $t-input-color, border-color $t-input-color;

  &:hover {
    background-color: $input-bg-hover;
  }
}

.stripe-input--focus {
  border-color: $input-border-focus;
  background-color: $input-bg-focus;
  transition: background-color $t-input-color, border-color $t-input-color;

  &:hover {
    background-color: $input-bg;
  }
}

.stripe-input--error {
  border-color: $input-border-error;
}

.stripe-input__errors {
  color: $input-error;
  font-size: 10px;
  padding-top: 3px;
}

.stripe-form__name-input {
  & input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }
}
