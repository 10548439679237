@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.close-button {
  @include size(12px);
  position: absolute;
  padding: 0;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  > svg {
    @include size(12px);
    fill: $c-black;
    transition: fill $t-close-button;
  }

  &:focus,
  &:hover {
    > svg {
      fill: $c-black;
    }
  }
}
