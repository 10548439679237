@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.transaction-card-field__wrapper {
  font-size: 12px;
  line-height: 16px;

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  .transaction-card-field__label {
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px;
  }

  .transaction-card-field__value {
    margin: 0;
    word-wrap: break-word;
  }

  &.transfer-date__wrapper {
    width: 135px;
    flex-shrink: 0;
  }
}
