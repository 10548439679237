@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.data__container {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.data-title {
  font-size: 12px;
  color: $c-add-bill-review-data-title;
  margin: 0 0 6px 0;
}

.data-value {
  font-size: 12px;
  color: $c-add-bill-review-data-value;
  margin: 0 0 20px 0;
  word-break: break-all;
}
