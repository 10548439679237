@import 'src/shared/styles/variables';
@import '~bootstrap/scss/bootstrap-grid';

.form {
  border: 1px solid $c-dark-grey;
  border-radius: 10px;
  margin: 0 auto;
  padding: 32px;
  width: 100%;
  max-width: 355px;

  @include media-breakpoint-up(md) {
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    max-width: 370px;
  }
}

.fields-container {
  margin-bottom: 32px;
}

.form__two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}
