@import '~bootstrap/scss/bootstrap-grid';

.form__wrapper {
  display: flex;

  @include media-breakpoint-up(lg) {
    padding-bottom: 32px;
  }
}

.form__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  width: 100%;
}

.button {
  max-width: 370px;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 170px;
  }
}

.last-field__wrapper,
.field__wrapper {
  max-width: 370px;

  > label {
    font-size: 13px;
    margin-bottom: 5px;
  }
}

.field__wrapper {
  margin-bottom: 50px;
}

.last-field__wrapper {
  margin-bottom: 40px;
}

.input__wrapper {
  > div {
    position: absolute;
  }
}
