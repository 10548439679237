@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

body {
  .select__form-field,
  .select {
    position: relative;
    border: 1px solid $c-select-input-border;
    color: $c-select-input;
    background-color: $c-select-input-bg;
    border-radius: 2px;
    height: 30px;
    padding: 0;
    font-weight: inherit;

    &:hover {
      background: $c-select-input-bg;
    }

    &__menu {
      box-shadow: none;
      margin-top: 0;
      border-radius: 0;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      border: 1px solid $c-select-input-border;
      border-top: 0;
      width: calc(100% + 2px);
      transform: translateX(-1px);
      font-weight: 400;
    }

    &__menu-list {
      padding: 0 3px 4px;
      background-color: $c-select-input-bg;
      border: 0;
      transform: translateY(-2px);
    }

    &__value-container {
      background: transparent;
      padding: 7px 6px 8px 13px;
      height: 28px;
    }

    &__control {
      background: transparent;
      border: 0;
      box-shadow: none;
      min-height: auto;
      cursor: pointer;

      &:after {
        @include size(8px, 5px);
        content: '';
        position: absolute;
        top: 12px;
        right: 13px;
        pointer-events: none;
        background: url('../../../../assets/icons/dropdown-arrow-icon.svg') no-repeat center;
        transition: transform 0.3s;
      }

      &--menu-is-open {
        font-weight: 400;

        &::after {
          transform: rotate(180deg);
          top: 12px;
        }
      }
    }

    &__single-value {
      color: inherit;
      max-width: calc(100% - 30px);
      font-size: 10px;
      line-height: 15px;
    }

    &__input {
      margin: 0;
      padding: 0;
    }

    &__option {
      border: 0;
      color: $c-select-input;
      background-color: $c-select-input-bg;
      cursor: pointer;
      margin: 0;
      padding: 3px 12px 4px;
      font-size: 10px;
      line-height: 15px;
      border-radius: 2px;
      margin-bottom: 1px;

      &:hover {
        background-color: $c-select-input-active-option-bg;
      }
    }

    &__placeholder {
      color: $c-select-input-placeholder;
      opacity: 0.54;
    }

    &__input {
      margin: 0;
      padding: 0;
      transform: translateY(-2px);
    }
  }

  .select__form-field {
    border-radius: 5px;
    height: 35px;
    border-color: $input-border;

    @include media-breakpoint-down(xs) {
      height: 30px;
    }

    &__clear-indicator {
      margin-right: 32px;
      padding: 0;

      & > svg {
        @include size(17px);
        fill: $c-gray-10;
      }
    }

    &__option {
      font-size: 12px;
      margin-bottom: 5px;

      @include media-breakpoint-down(xs) {
        font-size: 11px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__value-container {
      padding-left: 11px;
      height: 35px;

      @include media-breakpoint-down(xs) {
        padding-left: 8px;
        height: 30px;
      }
    }

    &__control {
      &:after {
        @include size(11px, 6px);
        top: 15px;

        @include media-breakpoint-down(xs) {
          top: 12px;
        }
      }

      &--menu-is-open {
        &::after {
          top: 15px;

          @include media-breakpoint-down(xs) {
            top: 12px;
          }
        }
      }
    }

    &__single-value {
      color: $c-select-input;
      font-size: 13px;
      line-height: 1.1;

      @include media-breakpoint-down(xs) {
        font-size: 11px;
      }
    }

    &__menu {
      border: 1px solid $c-select-input-border;
      border-radius: 10px;
      max-width: 100%;
      padding: 33px 13px 25px 9px;
      margin: 5px 0 5px;
    }

    &__menu-list {
      font-size: 12px;
      scrollbar-width: thin;
      scrollbar-color: $c-scrollbar-thumb $c-scrollbar-track-bg;

      &::-webkit-scrollbar {
        width: 5px;
        background-color: $c-scrollbar-track-bg;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $c-scrollbar-thumb;
      }
    }

    &__placeholder {
      @include media-breakpoint-down(xs) {
        font-size: 11px;
      }
    }
  }
}
