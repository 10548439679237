@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.app-loading {
  min-height: 50px;
  position: relative;
  pointer-events: none;
  user-select: none;
  z-index: $z-app-loading;
}

.app-loading__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($c-white, 0.5);
  z-index: $z-app-loading-overlay;
}

.app-loading__overlay--filled {
  border-radius: 10px;
  background-color: $c-white;
}

.app-loading__overlay--hidden,
.app-loading__spinner-wrapper--hidden {
  display: none;
}

.app-loading__spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $z-app-loading-spinner;
}

.spinner__wrapper {
  min-height: 50px;
  align-items: center;
}

.spinner {
  @include size(50px);
  position: relative;
  margin: auto;
}

.spinner__item {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 6px;
    background-color: $c-app-loading;
    animation: spinner-animation $t-app-loading infinite ease-in-out both;
  }
}

@for $i from 2 through $loader-items {
  .spinner__item--#{$i} {
    transform: rotate((360deg / $loader-items * ($i - 1)));

    &::before {
      animation-delay: (-$t-app-loading + $t-app-loading / $loader-items * ($i - 1));
    }
  }
}

@keyframes spinner-animation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
