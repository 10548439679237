@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.loading-overlay {
  width: calc(100% - 40px);
  left: 20px;

  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 5vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 350px;
    width: calc(100% - 60px);
    height: 100%;
    max-height: 88vh;
    z-index: $z-transaction-card;
  }
}

.loading-spinner {
  @include media-breakpoint-down(sm) {
    position: fixed;
    z-index: $z-transaction-card;
  }
}
