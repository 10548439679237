@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.layout--basic {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > * {
    flex: 0 0 auto;
  }
}

.layout__main {
  padding-top: 38px;
  padding-bottom: 17px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout__container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
