.invite__button,
.edit-description__button {
  padding: 5px 8px 5px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120px;
  line-height: 1;
}

.edit-description__button {
  display: flex;
  justify-content: center;
  padding: 5px 12px;
  min-width: unset !important;
  width: 95px;
}
