@import 'src/shared/styles/variables';

.content-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 116.5px 20.5px 110.5px;
}

.title {
  color: $c-black;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-top: 30px;
  text-align: center;
}

.farewell {
  font-size: 16px;
  line-height: 20px;
  max-width: 318px;
  text-align: center;
}
