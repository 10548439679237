@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.footer {
  color: $text-footer-privacy-color;
  font-size: 12px;
  font-family: $font;
  font-weight: 400;
  width: 100%;

    .footer__wrapper {
      @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: center;
      }
    }
}

.footer__link {
  color: $text-footer-privacy-color;
  border-bottom: 1px solid $text-footer-privacy-color;

  &:first-child {
    margin-right: 3px;
  }
  &:last-child {
    margin-left: 3px;
  }

  &:hover {
    color: $text-footer-privacy-color;
  }

  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }
}
