@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';
@import '~bootstrap/scss/bootstrap-grid';

.collapse__wrapper {
  .entity-short-card {
    margin-bottom: 4px;
  }
}

.entity-short-card {
  display: flex;
  align-items: flex-start;
  padding: 4px;
  border-radius: 5px;
  outline: none;

  &.active {
    background: $c-active-entity-card-bg;
  }

  &.is-clickable {
    cursor: pointer;
  }

  &.invoice_vendor {
    padding: 6px 0;

    .entity-short-card__info-wrapper {
      width: 100%;
      overflow: hidden;
      max-width: calc(100% - 10px);
    }

    .entity-short-card__info {
      -webkit-line-clamp: 1;
      transform: translateY(1px);
    }

    .entity-short-card__info,
    .entity-short-card__help-text {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.entity-short-card__info {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  max-height: 48px;
  overflow: hidden;
  color: $c-entity-card-text;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.entity-short-card__help-text {
  margin: 0;
  font-size: 10px;
  line-height: 13px;
  color: $c-entity-card-help-text;
  transform: translateY(1px);
}

.invoice-details-card {
  padding: 0 !important;
  margin-bottom: 19px;

  .entity-short-card__info-wrapper {
    min-width: 75px;
    flex: 1;
  }

  .entity-short-card__info,
  .entity-short-card__help-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .entity-short-card__help-text {
    font-size: 12px;
    line-height: 15px;
  }
}

.billing-company-info {
  display: flex;
  align-items: flex-start;
  margin-bottom: 19px;
  padding: 3px 0 0 0 !important;

  @include media-breakpoint-down(sm) {
    padding-top: 0 !important;
  }

  .entity-short-card__info-wrapper {
    min-width: 0;
    flex: 1;
    padding-top: 4px;

    @include media-breakpoint-down(sm) {
      padding-top: 0 !important;
    }
  }
  .entity-short-card__info,
  .entity-short-card__help-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
