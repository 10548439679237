@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.close-button {
  top: 41px;
  right: 20px;

  @include media-breakpoint-up(lg) {
    right: 64px;
  }
}

.field-wrapper {
  max-width: none;

  @include media-breakpoint-up(sm) {
    max-width: 370px;
  }
}
