@import 'src/shared/styles/variables';

.footer {
  border-top: 1px solid $c-form-border;
  display: flex;
  justify-content: flex-end;
  margin: auto 19px 0;
  padding-top: 18px;
}

.footer > button:last-child {
  margin-left: 17px;
}
