@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.simplebar-scrollbar,
.simplebar-scrollbar:before {
  background: $c-scrollbar-bg;
  border-radius: 2px;
}

.simplebar-content-wrapper {
  padding-right: 2px;
}

.simplebar-track {
  background: $c-scrollbar-track-bg;
  border-radius: 2px;

  &.simplebar-vertical {
    width: 5px;
    margin-left: 5px;
  }

  &.simplebar-horizontal {
    height: 5px;
  }
}

.simplebar-mask {
  z-index: $z-simplebar-mask;
}

.transaction-history {
  .simplebar-track {
    &.simplebar-vertical {
      height: calc(100% - 9px);
    }
  }
}
