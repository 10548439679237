@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.account-integration__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  background-color: $c-light-gray-8;
  border-radius: 10px;
  padding: 19px 10px 24px 10px;

  @include media-breakpoint-up(md) {
    padding: 19px 48px 24px 22px;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.account-integration-info {
  display: flex;
  align-items: center;
}

.company-logo {
  & > img {
    width: 54px;
    height: 54px;
  }

  & > span {
    width: 21px;
    height: 21px;
    bottom: -8px;
    right: -7px;
  }
}

.details-account__wrapper {
  margin-left: 20px;
}

.account-name {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
}

.account-subtitle {
  margin: 0;
  max-width: 236px;
  font-size: 10px;
  line-height: 12px;

  @include media-breakpoint-up(sm) {
    font-size: 12px;
    line-height: 16px;
  }
}

.account-integration-btn {
  font-size: 13px;
  padding-top: 14px !important;
}
