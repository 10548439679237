@import '~bootstrap/scss/bootstrap-grid';

.customer-form__body {
  margin-bottom: 33px;
  min-height: 228px;
}

.customer-form__field {
  &:not(:last-child) {
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
      margin-bottom: 18px;
    }
  }
}

.customer-form__input {
  padding: 14px 16px;
}

.customer-form__button {
  padding: 0 27px;
}
