@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.verification-form__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include media-breakpoint-up(lg) {
    padding-bottom: 76px;
  }
}

.link__wrapper {
  display: flex;
  flex-direction: row;
  font-family: $font-complementary;
  font-size: 12px;
  line-height: 16px;
  margin-top: 32px;
}

.link__text {
  color: $c-black;
}

.link__button {
  color: $accent-color;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  font-size: 12px;
  line-height: 15px;
  font-family: $font-complementary;
  padding: 0;
}
