@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.table-header__wrapper {
  border-bottom: 1px solid $c-table-header-border;
}

.table-header__cell {
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  color: $c-table-header;
  text-align: left;
  width: 100%;
  padding: 9px 0;
  background: transparent;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  font-family: $font;

  .table-header__sort-arrow {
    fill: $c-table-header;
    transition: transform 0.3s;
    margin-left: 2px;
  }

  &.table-header__sortable-button {
    width: auto;
    padding: 0;
  }

  &.table-header__sortable-cell {
    cursor: pointer;
  }

  &.table-header__sortable-cell--active {
    color: $c-table-header-active;

    svg {
      fill: $c-table-header-active;
    }
  }

  .table-header__sort-arrow--asc {
    transform: rotate(180deg) translateY(-1px);
  }
}
