@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
  margin-top: 30px;
}

.button {
  &:first-child {
    margin-right: 20px;
  }
}
