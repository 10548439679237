@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.modal-form {
  padding: 13px 20px;
  margin-top: 30px;

  @include media-breakpoint-up(lg) {
    padding: 13px 67px;
  }
}

.modal-form__title {
  color: $c-form-title;
  margin-bottom: 12px;
  position: relative;
  bottom: 4px;
}

.modal-form__content {
  padding: 22px 10px 37px;
  border-top: 1px solid $c-form-border;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    max-width: 370px;
  }

  @include media-breakpoint-up(lg) {
    padding: 22px 20px 37px;
  }
}

.name-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}

.modal-form__name-container {
  display: flex;
  grid-column-gap: 0;
}

.modal-form__firstname-input {
  margin-right: 15px;
}

.checkbox {
  border-radius: 3px;
  height: 10px;
  width: 10px;
  background-color: $c-dark-blue;
}

.address-input {
  margin-bottom: 8px;
}
