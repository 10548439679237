@import 'src/shared/styles/variables';
@import '~bootstrap/scss/bootstrap-grid';

.content-block {
  background-color: $c-white;
  border-radius: 10px;
  max-width: 499px;
  padding: 126px 76.5px 120px;
  text-align: center;
  width: 100%;
  min-width: 499px;

  @include media-breakpoint-down(sm) {
    min-width: auto;
    padding: 70px 30px 70px;
  }
}

.title {
  color: $c-black;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-top: 27px;
}

.farewell {
  font-size: 16px;
  line-height: 20px;
}
