@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.popup__wrapper {
  max-width: 315px;
}

.popup__title {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 20px;
}

.popup__body {
  margin: 0 0 25px;
}

.popup__company {
  font-weight: 500;
  margin-left: 5px;
}

.popup__button {
  display: inline-block;
}
