@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.info-confirm__wrapper {
  margin-top: 54px;
  padding: 0 32px;
  margin-bottom: 118px;

  @include media-breakpoint-down(xs) {
    padding: 0;
  }
}

.add-confirm-margin {
  margin-bottom: 164px;
}

.confirm-item__wrapper {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 36px;
  }
}

.confirm-amount-edit {
  margin-top: 2px;
  margin-left: 10px;
  fill: $c-blue;
  cursor: pointer;
}

.confirm-item__name {
  font-size: 13px;
  line-height: 12px;
  color: $c-dark-blue;
  flex-basis: 33.5%;
}

.confirm-item__value {
  flex-basis: 66.5%;
}

.confirm-item-amount__value {
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  word-wrap: break-word;
  max-width: 221px;

  &:first-letter {
    color: $c-green;
  }

  @include media-breakpoint-down(sm) {
    max-width: 176px;
  }

  @include media-breakpoint-down(xs) {
    max-width: 161px;
  }
}

.confirm-item-bank__value {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 6px;
}

.confirm-item-act__value {
  font-size: 12px;
  line-height: 16px;
}

.confirm-additional__text {
  padding-left: 15px;
  margin-bottom: 5px;
  font-size: 12.5px;
  line-height: 15px;
  color: $c-gray-1;
}
