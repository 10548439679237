@import 'src/shared/styles/variables';
@import '~bootstrap/scss/bootstrap-grid';

.payable-receivable-content {
  background: $c-white;
  padding: 32px 49px 32px 29px;
  border-radius: 10px;
  height: 454px;
  border: 1px solid $c-block-border;
  position: relative;

  @include media-breakpoint-down(sm) {
    padding: 23px;
  }
}

.payable-receivable__title {
  font-size: 12px;
  line-height: 16px;
  color: $c-accounting-dashboard-title;
  margin-bottom: 13px;
}

.payable-receivable__amount-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.payable-receivable__count-of-items {
  color: $c-accounting-dashboard-counter;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  transform: translateY(-5px);
  white-space: nowrap;
  margin-top: 10px;

  &:after {
    content: '';
    background: $c-accounting-dashboard-counter;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    bottom: -1px;
  }
}

.payable-receivable__amount {
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
  margin-right: 10px;

  &:first-letter {
    color: $c-green;
  }
}

.payable-receivable__aging-wrapper {
  display: flex;
  align-self: center;
  margin: 8px 0;
}

.payable-receivable__aging {
  font-size: 12px;
  line-height: 15px;
  color: $c-gray-1;

  &:not(:last-child) {
    display: flex;
    align-items: center;

    &::after {
      display: block;
      content: '';
      background: $c-gray-1;
      width: 1px;
      height: 12px;
      margin: 0 16px 0 11px;
    }
  }
}

.payable-receivable__companies-list {
  margin-top: 28px;

  @include media-breakpoint-up(md) {
    position: absolute;
    bottom: 24px;
    padding-right: 78px;
    width: 100%;
    margin: 0;
  }
}

.payable-receivable__companies-title {
  font-size: 12px;
  line-height: 16px;
  color: $c-accounting-dashboard-title;
  margin-bottom: 14px;
}

.payable-receivable__companies-link {
  font-size: 10px;
  line-height: 20px;
  color: $c-accounting-dashboard-link;
  padding: 0 3px !important;
  margin-right: 15px;
  margin-left: 20px;
  align-self: center;
  transform: translateY(2px);
}
