@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.confirmation-modal {
  max-height: 200px;
  height: calc(100vh - 40px);
  padding: 26px 27px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.confirmation-modal__title {
  font-size: 12px;
  line-height: 16px;
  margin: 8px 0 27px;
  color: $c-black;
  max-width: 150px;
  text-align: center;
}

.confirmation-modal__text {
  margin: 0 0 20px;
  padding-bottom: 10px;
}

.confirmation-modal__controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: nowrap;

  & button {
    &:first-child {
      margin-right: 20px;
      width: 78px;
      min-width: auto;
    }
  }
}

.unsaved-changes-modal__title {
  max-width: 200px;
}
