@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.entity-logo__wrapper {
  position: relative;
  display: inline-block;
  margin-right: 16px;
  line-height: 0;

  &.has-opacity {
    .entity-logo {
      opacity: 0.5;
    }
  }
}

.entity-logo {
  @include size(29px);

  object-fit: cover;
  border-radius: 5px;

  &.has-border {
    border: 1px solid $c-entity-logo-border;
  }
}

.entity-logo__icon {
  @include size(13px);

  position: absolute;
  bottom: -3px;
  right: -4px;
  background: $c-entity-logo-icon-bg;
  border: 1px solid $c-entity-logo-icon-bg;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transaction-card__logo {
  img {
    width: 46px;
    height: 46px;
    border-radius: 6px;
  }

  .entity-logo__icon {
    bottom: -4px;
    right: -3px;
  }
}

.schedule-invoice-icon {
  transform: translate(0.5px, 0.5px);
}
