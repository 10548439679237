@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.form-wrapper {
  max-width: 388px;

  @include media-breakpoint-down(sm) {
    margin: auto;
  }

  p {
    font-size: 12px;
    line-height: 16px;
  }
}

.verification-header {
  margin-bottom: 16px;

  > div {
    margin-top: 9px;
  }

  &.accounts-header {
    margin-bottom: 10px;
    padding-right: 40px;

    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }
}
