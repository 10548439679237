@import 'src/shared/styles/variables';

.password-field__wrapper {
  padding-top: 10px;
}

.password-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 13px;
  margin-top: 1px;
}

.password-field__label {
  padding-top: 2px;
}

.password-field__stars {
  margin-left: 12px;
  padding-bottom: 12px;
}

.password-field__link {
  font-size: 13px;
  color: $c-settings-change-password;
  padding: 0;
  margin: 0;
  cursor: pointer;
  margin-right: 5px;
  outline: none;
  border: none;
  background: none;
  padding-bottom: 10px;

  &:hover {
    color: $c-settings-change-password !important;
    background: transparent !important;
    border-color: transparent !important;
  }
}
