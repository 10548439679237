@import '~bootstrap/scss/bootstrap-grid';

.payment-method {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px 15px;
  overflow-y: auto;
  max-height: 90vh;

  @include media-breakpoint-up(sm) {
    padding: 27px 31px 33px;
  }
}
