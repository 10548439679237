@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.external-payment-datepicker {
  > .react-datepicker-wrapper {
    width: auto !important;
  }

  .react-datepicker-popper {
    &[data-placement^='top-start'] {
      margin-left: -41px;
    }
  }
}
