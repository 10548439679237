@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.react-datepicker-wrapper {
  width: 100% !important;

  button {
    &.datepicker--active {
      color: $c-datepicker !important;

      svg {
        fill: $c-datepicker-active !important;
      }
    }
  }
}

.react-datepicker__help-text {
  color: $c-datepicker-calendar-selected-day;
  text-align: end;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0px;
  margin-right: 28px;
  margin-top: 11px;

  &::before {
    content: '\A';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $c-datepicker-calendar-selected-day;
    display: inline-block;
    margin-right: 5px;
  }
}

.react-datepicker-popper {
  background: $c-datepicker-calendar-bg;
  border: 0.5px solid $c-datepicker-calendar-border;
  border-radius: 10px 10px 0 0;
  padding: 19px 16px 10px 15px;
  box-sizing: border-box;
  z-index: $z-calendar;

  &[data-placement^='left'] {
    margin-right: 14px;
  }

  .react-datepicker__header {
    background: transparent;
    border: 0;
    padding-top: 0;
  }

  .react-datepicker__day-names {
    margin-top: 12px;

    .react-datepicker__day-name {
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      color: $c-datepicker-calendar-day-name;
      font-family: $font-complementary;
      margin: 3px 3px 3px 2px;
    }
  }

  .react-datepicker__day {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: $c-datepicker-calendar-day;
    margin: 3px 3px 8px 3px;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: transparent;
    }

    &.react-datepicker__day--selected,
    &.react-datepicker__day--keyboard-selected {
      color: $c-datepicker-calendar-selected-day;
      background: transparent;
    }

    &.react-datepicker__day--disabled {
      color: $c-datepicker-calendar-disabled-day;
    }

    &.react-datepicker__day--outside-month {
      color: $c-datepicker-calendar-inactive-day;
    }

    &.react-datepicker__day--highlighted {
      color: $c-datepicker-calendar-selected-day;
      background: transparent;
      position: relative;

      &::before {
        content: '\2022';
        position: absolute;
        left: 40%;
        top: -11px;
      }
    }
  }

  .react-datepicker__current-month {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: $c-datepicker-calendar-day;
  }

  .react-datepicker {
    border: 0 !important;
    font-family: $font;
  }

  .react-datepicker__navigation {
    @include size(7px, 11px);
    border: 0;
    top: 4px;

    &::before {
      @include size(7px, 11px);
      content: '';
      background: url('../../../../assets/icons/calendar-navigation-arrow.svg') no-repeat center;
      display: block;
    }

    &.react-datepicker__navigation--previous {
      left: 19px;
    }

    &.react-datepicker__navigation--next {
      right: 18px;

      &::before {
        transform: rotate(180deg);
      }
    }
  }

  .react-datepicker__triangle {
    display: none !important;
  }
}

.external-payment-datepicker {
  .react-datepicker-popper {
    &[data-placement^='right'] {
      top: -111px !important;
      left: -20px !important;
    }
  }
}
