@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.back-button__wrapper {
  position: inherit;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.title,
.title__invoice {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 22px;
}

.title__invoice {
  font-size: 24px;
  margin-bottom: 25px;
}

.invoice-success-message {
  font-size: 25px;
  font-weight: 600;
}

.success-message-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invite-business__wrapper {
  padding: 28px 30px 29px;
  min-width: 350px;
  min-height: 314px;
}

.invoice-details__wrapper {
  width: 85vw;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 655px;
  max-width: 600px;
  padding: 26px 30px 32px;
  position: relative;
}

.invite-business__inner {
  width: 100%;
  max-width: 470px;
}
