@import 'src/shared/styles/variables';

.cropper {
  position: relative;
  width: 400px;
  height: 400px;
  margin-bottom: 20px;
}

.cropper__wrapper {
  width: 400px;
  height: 400px;
  background-color: white;
  z-index: $z-cropper;
  border-radius: 10px;
}

.cropper__btn {
  display: flex;
  justify-content: space-between;
}
