@import-normalize;
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/_spinners.scss';
@import '~bootstrap/scss/_mixins.scss';
@import '~bootstrap/scss/_tooltip.scss';
@import '~react-contexify/dist/ReactContexify.css';
@import 'shared/styles/variables';
@import 'shared/styles/fonts';

html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));

  @include media-breakpoint-down(xs) {
    overflow-x: visible;
    margin-right: 0;
  }
}

body {
  margin: 0;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  color: $text-color;
  font-size: 12px;
  line-height: 1.25;
  overflow: visible !important;
}

a {
  text-decoration: none;
  color: $accent-color;
  transition: all $t-default;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:active {
    color: $accent-color;
  }
}

h1,
h2,
h3 {
  font-weight: 400;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: 30px;
  line-height: 38px;
}

h2 {
  font-size: 16px;
  line-height: 20px;
}

h3 {
  font-size: 12px;
  line-height: 16px;
}

ul,
ol {
  padding-left: 20px;

  li {
    margin-bottom: 5px;
  }
}

.public-form-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 355px;
  border-radius: 10px;

  @include media-breakpoint-up(md) {
    margin: 0;
    padding: 31px;
    border: 1px solid $c-form-border;
  }

  @include media-breakpoint-up(lg) {
    max-width: 388px;
  }
}

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
  @include media-breakpoint-up($grid-breakpoint) {
    $grid-gutter-half-width: $grid-gutter-width / 2;
    .row:not(.no-gutters) {
      margin-right: -$grid-gutter-half-width;
      margin-left: -$grid-gutter-half-width;
    }
    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*='col-'] {
      padding-right: $grid-gutter-half-width;
      padding-left: $grid-gutter-half-width;
    }
  }
}

header,
main,
footer,
.toasts-wrapper {
  > .row:not(.no-gutters) {
    @include media-breakpoint-down(lg) {
      margin: 0;
    }
  }

  .layout__container {
    max-width: $max-container-width;
  }

  .layout__container-wrapper {
    @include make-grid-columns(10, 20px);
    position: relative;
    padding: 0 20px;

    @include media-breakpoint-up(lg) {
      padding-left: 8.5%;
      padding-right: 8.5%;
    }
  }
}

.toasts-wrapper.layout__container {
  max-width: $max-container-width !important;
}
