@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.company-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 22px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.company-addition-info {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 3px;
}

.client-link__wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.client-img {
  margin-right: 17px;
}
