@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.layout--public-full {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > * {
    flex: 0 0 auto;
  }
}

.layout--public {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 48px 0 24px;

  @media (max-width: 1280px) {
    align-items: center;
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}

.layout__main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.public-profile {
  > main {
    justify-content: flex-start;
    padding-top: 31px;
  }
}
