@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.scrollbar {
  max-height: 303px;
  width: 100%;
}

.loader__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loader__wrapper--active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader__wrapper--empty {
  padding-top: 10px;
  text-align: center;
}

.company-list__empty {
  font-size: 12px;
  margin-bottom: 20px;
  white-space: nowrap;
}

.company-list {
  width: 100%;
  padding: 0 3px;
}

.company-list__title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  padding: 21px 0 0 24px;
  margin-bottom: 10px;
}
