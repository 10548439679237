@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.not-found {
  width: 100%;
  min-height: 99vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-notFound;
  background-color: $bg-color;
  padding-top: 48px;

  & > * {
    flex: 0 0 auto;
  }
}

.not-found__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 66px;
  height: 100%;
}

.not-found__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-found__title {
  font-size: 200px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 6px;

  @include media-breakpoint-down(sm) {
    font-size: 60px;
  }
}

.not-found__subtitle {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 30px;
}
