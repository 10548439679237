@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.company__description {
  border-radius: 10px;
  background: $c-company-description-bg;
  padding: 29px;
  height: 250px;
  min-height: 250px;
  position: relative;
  border: 1px solid $c-block-border;

  @include media-breakpoint-down(md) {
    margin-bottom: 21px;
    height: inherit;
  }
}

.ghosty__description {
  padding-top: 21px;
}

.empty__description {
  padding-top: 21px;
  padding-left: 30px;
}

.subtitle__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.5px solid $c-company-description-border;
  padding-bottom: 2px;
  margin: 0 15px 36px 0;
}

.ghosty__subtitle {
  margin-bottom: 17px;
}

.company__subtitle {
  color: $c-black;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.description__wrapper {
  height: 77%;
}

.empty-description__wrapper {
  height: 56% !important;
  justify-content: center;
}

.company__about {
  font-size: 12px;
  line-height: 16px;
  position: relative;
  -webkit-line-clamp: 8;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ghosty__about {
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 28px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.edit-description__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  width: 20px;
  height: 20px;
  transform: translate(-1px, -2px);

  &:hover,
  &:focus {
    cursor: pointer;
  }

  svg {
    fill: $c-dark-grey;

    &.edit-description-icon__active {
      fill: $accent-color;
    }
  }
}

.editable-content {
  border: 0.5px solid $c-gray;
  height: 123px;
  width: calc(100% - 13px);
  padding: 13px 17px;
  line-height: 16px;
  margin-top: -14px;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: $c-scrollbar-track-bg;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $c-scrollbar-thumb;
  }
}

.edit-panel {
  position: absolute;
  right: 41px;
  bottom: 9px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 175px;
}

.save-button {
  min-height: unset !important;
  height: 26px;
  min-width: 72px !important;
  padding: 4px 12px !important;
}

.cancel-button {
  background-color: transparent;
  border: none;
  color: $c-red;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    border: none !important;
    color: $c-red !important;
    cursor: pointer;
  }
}

.empty-description-invitation__wrapper {
  margin-left: 0;
  margin-top: 7px;
  bottom: 73px !important;
}

.edit-description-invitation__wrapper {
  margin-left: 0;
  margin-top: 7px;
  left: -62px !important;
  bottom: 76px !important;
}

.invitation__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 75px;
  position: absolute;
  left: 2px;
  bottom: 30px;

  @include media-breakpoint-down(md) {
    margin-left: 0;
    width: 90%;
  }
}

.invitation__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 55px;

  @include media-breakpoint-down(md) {
    margin-left: 25px;
  }
}

.invitation__text-block {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 16px;
}

.invitation__image {
  margin-top: 4px;
}

.invitation__title {
  font-weight: 600;
}
