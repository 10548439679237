@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.datepicker {
  position: relative;
  text-align: left;
  width: 195px;
}

.datepicker__control-icon {
  @include size(15px);
  position: absolute;
  right: 12px;
  top: 9px;
  fill: $c-light-gray-1;
}

.fields__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  margin-bottom: 15px;
}

.field__inner {
  margin-bottom: 0;
}

.field__label {
  font-size: 11px;
  color: $textarea-letters-counter;
  font-weight: 300;
}
