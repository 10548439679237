@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.bank-account__container {
  background-color: $c-light-gray-8;
  border-radius: 10px;
  display: flex;
  padding: 15px 18px;
  position: relative;
  margin-bottom: 16px;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.icon-container {
  margin-right: 20px;
  transform: translateY(1px);
}

.bank-logo {
  margin-right: -3px;
  transform: translateX(-1px);
}

.name {
  font-size: 12px;
  font-family: $font-euclid;
  margin-right: auto;

  p {
    margin: 0;
  }

  .bank {
    font-weight: 500;
    margin-bottom: 2px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.actions {
  flex-shrink: 0;
  margin-right: 0;
  margin-left: 15px;
}

.delete-icon {
  fill: $c-red;
  transform: translateY(2px);

  svg {
    @include size(16px);
  }
}

.icon {
  cursor: pointer;
  border: none;
  background-color: inherit;
  outline: none;

  &:hover,
  &:focus {
    fill: blue;
  }
}
