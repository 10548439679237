.number-input-center__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.helper-span {
  visibility: hidden;
  position: absolute;
}
