@import 'src/shared/styles/variables';
@import '~bootstrap/scss/bootstrap-grid';

.field__content-wrapper {
  align-items: center;
  display: grid;

  &:not(:last-child) {
    margin-bottom: 15px;
    padding-bottom: 8px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    grid-template-rows: 1fr 50px;
    grid-row-gap: 10px;
    width: 100%;
  }

  @include media-breakpoint-down(xs) {
    grid-template-rows: 1fr 30px;
    grid-row-gap: 20px;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 273px;
    grid-column-gap: 20px;
    justify-content: space-between;
  }
}

.field__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 15px;
  margin-top: -32px;
}

.field__content-title {
  position: relative;
  color: $c-entry-input-label;
  font-weight: 500;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }
}

.field__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: $text-color;
  transition: font-weight $t-settings-field-header, color $t-settings-field-header;
}
