@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.title-wrapper {
  position: relative;
  min-height: 275px;
  display: flex;
  align-items: center;
}

.layout__main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.page-title {
  font-size: 64px;
  margin: 0;

  @include media-breakpoint-down(sm) {
    font-size: 60px;
  }
}

.layout__shape {
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 275px;
  fill: url(#shape-fill);

  @include media-breakpoint-down(sm) {
    height: 200px;
  }
}

.login-button {
  display: block;
  margin-left: auto;
  min-width: 180px;
}
