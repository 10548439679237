@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.schedule-field__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
}

.edit-button {
  cursor: pointer;
  color: $c-transaction-card-edit;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: transparent;
  padding: 0;
  border: 0;

  > svg {
    fill: $c-transaction-card-edit;
  }
}

.scheduled-invoice-footer__wrapper {
  > div {
    border-top: 0;
    background: $c-transaction-card-footer-bg;
    margin-left: -32px;
    margin-right: -22px;
    margin-bottom: -18px;
    padding: 3px 16px 5px 32px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @include media-breakpoint-down(sm) {
      margin-left: -22px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 5px;
    }
  }

  .schedule-message__wrapper {
    display: flex;
    align-items: center;
  }

  .clock-icon {
    margin-right: 7px;
    flex-shrink: 0;
  }
}
