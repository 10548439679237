@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.payment-options {
  margin: 0 auto;
}

.payment-options__title {
  font-size: 22px;
  font-weight: 600;
  color: $headings-color;
}

.payment-options__radio {
  margin-bottom: 18px;
}

.payment-options__buttons {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
}
