@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.list__button {
  background-color: transparent;
  border: none;
  color: $accent-color;
  font-weight: 500;
  cursor: pointer;
  text-align: left;
  font-size: 12px;
  font-family: inherit;
  outline: none;
  text-transform: lowercase;
  width: 100%;
  padding: 2px 12px;

  &:not(disabled):hover {
    color: inherit;
  }
}

.option__wrapper {
  padding: 2px 12px;
  cursor: pointer;
}

.select {
  padding: 0;
}

.accounts-item__name {
  margin: 0;
  font-size: 13px;
  font-weight: 300;
  text-transform: capitalize;
  line-height: 1.2;
}

.accounts-item__number {
  font-size: 12px;
  margin: 0;
  line-height: 1.3;
}

.accounts-item__number--card {
  text-transform: uppercase;
}

.accounts-items__button-container {
  padding: 0;
  text-align: right;
}
