@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.main-nav {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-family: $font;
}

.main-nav__link {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  padding: 22px 0 17px;
  color: $nav-color;

  &:hover,
  &:focus,
  &:active {
    color: $nav-color;

    .main-nav__icon-wrapper {
      svg {
        fill: $nav-color;
      }
    }
  }

  .main-nav__icon-wrapper {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    width: 11px;
    height: 11px;
    transform: translateY(-1px);

    svg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      opacity: 1;
      fill: $nav-color;
      transition: all 0.3s;
      width: 11px;
      height: 11px;
    }
  }

  &:not(:last-child) {
    margin-right: 27px;
  }

  &::after {
    content: '';
    background: $nav-active-color;
    border-radius: 2px;
    opacity: 0;
    height: 2px;
    width: 100%;
    bottom: -1px;
    left: 0;
    position: absolute;
    transition: opacity 0.3s;
  }
}

.main-nav__link--active {
  color: $nav-active-color;

  .main-nav__icon-wrapper {
    svg {
      fill: $nav-active-color;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $nav-active-color;
    text-decoration: none;

    .main-nav__icon-wrapper {
      svg {
        fill: $nav-active-color;
      }
    }
  }

  &::after {
    opacity: 1;
  }
}
