@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.completed-dashboard-item {
  background: $c-white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  padding: 14px 26px;
  border: 1px solid $c-block-border;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .completed-dashboard-item__title {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 1px;
    color: $c-black;
  }

  .completed-dashboard-item__amount-wrapper {
    font-size: 25px;
    line-height: 32px;
    color: $c-black;
    font-weight: 500;
    margin-bottom: 3px;
  }

  .completed-dashboard-item__amount {
    display: inline-block;

    &:first-letter {
      color: $c-green;
    }
  }

  .completed-dashboard-item__amount-minus {
    color: $c-green;
  }

  .completed-dashboard-item__subtitle {
    font-size: 12px;
    line-height: 15px;
    color: $c-gray-1;
  }
}
