@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.in-app-notifications {
  padding: 22px 22px 45px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 21px;
  }

  .collapse__title {
    margin-bottom: 7px;
  }

  .collapse-button__wrapper {
    height: 109px;
    padding-bottom: 17px;
    padding-right: 0;
    background: linear-gradient(180deg, rgba($c-notifications-bg, 0) -2.34%, $c-notifications-bg 47.42%);
  }

  .in-app-notifications__logo {
    @include media-breakpoint-up(sm) {
      margin-right: 40px;
    }

    .entity-logo {
      @include size(31px, 30px);
    }
  }
}
