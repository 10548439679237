@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.container {
  padding-top: 31px;
  width: 100%;
  max-width: 430px;
  font-size: 18px;
  align-items: flex-start;
}
