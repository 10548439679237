.page-title {
  padding-top: 7px;
}

.accounting-balance {
  padding-top: 29px;
  padding-bottom: 25px;

  hr {
    margin-top: 14px;
  }
}

.accounting-header__wrapper {
  margin-bottom: 14px;
}
