@import 'src/shared/styles/variables';
@import '~bootstrap/scss/bootstrap-grid';

.tfa-fields__wrapper--disabled {
  display: none;
}

.phone-field__wrapper {
  position: relative;
  margin-bottom: 50px !important;

  @include media-breakpoint-up(sm) {
    margin-bottom: 23px !important;
  }
}

.submit-code {
  height: 30px;
  position: relative;
  top: 5px;
}

.send-code {
  color: $c-settings-send-code;
  position: absolute;
  top: 50px;
  left: 0;

  &:hover,
  &:focus {
    color: $c-settings-send-code !important;
  }

  &:disabled {
    border-color: transparent;
    background: transparent;
  }

  @include media-breakpoint-up(sm) {
    top: 11px;
    right: 23px;
    left: unset;
  }
}

.code-input__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 530px;
}

.close-account__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 13px;
  margin-top: 36px;
}

.close-account__wrapper--top {
  margin-top: 0;
}

.button-link {
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  min-width: auto;
  min-height: auto;
  font-size: 13px;

  &:hover,
  &:focus {
    background: transparent !important;
    border-color: transparent !important;
  }
}

.close-account {
  color: $c-red;
  margin-bottom: 2px;

  &:hover,
  &:focus {
    color: $c-red !important;
  }
}

.tip {
  color: $c-gray-12;
}

.confirm-modal__title {
  margin-top: 13px;
  margin-bottom: 0;
  font-weight: 700;
  max-width: 220px;
}

.confirm-modal__content {
  font-size: 12px;
  margin-bottom: 18px;
  padding-bottom: 0;
}
