@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.verify-star {
  fill: $c-unverified-account;

  &.active {
    fill: $c-verified-account;
  }
}
