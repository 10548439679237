@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.top-section-container {
  position: relative;
  padding-top: 9px;
  padding-bottom: 18px;
  border-bottom: 0.5px solid $c-gray-7;
  margin-bottom: 19px;
}

.banner-poster {
  background-color: $accent-color;
  border-radius: 10px;
  height: 177px;
  margin-bottom: -36px;
}

.poster-image {
  border-radius: 10px;
  width: 100%;
  height: 177px;
  object-fit: cover;
  object-position: 0 0;
}

.edit-banner-button {
  position: absolute;
  top: 19px;
  right: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(241, 242, 246, 0.58);
  border: none;
  width: 24px;
  height: 24px;

  &:disabled {
    cursor: not-allowed;
  }
}

.edit-banner-button__icon {
  fill: white;
  width: 15px;
  height: 15px;
  transform: translateY(-1px);
}
