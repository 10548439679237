@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.due-date__wrapper {
  padding: 3px 8px 2px 7px;
  border-radius: 4px;

  &.completed {
    background: $c-completed-due-date-bg;
    color: $c-completed-due-date-text;
    font-weight: 600;
  }

  &.closeToDate {
    background: $c-close-to-date-due-date-bg;
    color: $c-close-to-date-due-date-text;
    font-weight: 600;
  }

  &.overdue {
    background: $c-overdue-due-date-bg;
    color: $c-overdue-due-date-text;
    font-weight: 600;
  }
}
