@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.page__wrapper {
  margin-top: -5px;
}

.page__title {
  text-align: center;
  font-size: 25px;
  margin-bottom: 5px;
  padding-left: 10px;
  font-weight: 500;
}

.page__subtitle {
  text-align: center;
  padding-left: 10px;
  margin: 0 0 21px;
}

.page__content {
  width: 100%;
  border-radius: 10px;
  background: $bg-color;
  padding: 29px 23px 20px 23px;
  min-height: 200px;
  border: 1px solid $c-form-border;
  margin-bottom: 2px;

  @include media-breakpoint-up(sm) {
    width: 480px;
    padding: 29px 35px 20px 33px;
  }
}

.page__invoice-owner {
  margin-bottom: 35px;
}

.page__submit-button {
  padding: 0 28px;
}
