@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.page__wrapper {
  padding-top: 7px;
}

.page__title {
  text-align: center;
  margin-bottom: 36px;
}

.page__content {
  width: 100%;
  border-radius: 10px;
  background: $bg-color;
  box-shadow: 0 4px 45px $c-form-shadow;
  padding: 29px 23px 20px 23px;
  border: 1px solid $c-block-border;

  @include media-breakpoint-up(sm) {
    width: 480px;
    padding: 29px 35px 20px 33px;
  }

  @include media-breakpoint-down(xs) {
    max-width: 280px;
  }
}

.page__invoice-owner {
  margin-bottom: 24px;
}

.page__return-btn {
  position: absolute;
  top: 0;
  left: -13px;

  @include media-breakpoint-up(sm) {
    top: 24px;
    left: -13px;
  }
}
