@import 'src/shared/styles/variables';

.account-tab__container {
  position: relative;
  padding-bottom: 78px;
  margin-bottom: 25px;
}

.account-block__wrapper {
  margin-bottom: 39px;
}

.business-information-block__wrapper {
  margin-bottom: 45px;
}

.authentification-block__title {
  margin-bottom: 21px;
}

.field__wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 422px;
  margin-bottom: 21px;
}

.field-label {
  width: 55%;
  margin: 0;
  padding-top: 12px;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: $c-entry-input-label;
}

.input__wrapper {
  width: 100%;
}

.buttons__container {
  position: absolute;
  bottom: 0;
  left: 48%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
}

.button-cancel {
  margin-right: 20px;
  width: 97px;
}

.button-submit {
  width: 122px;
}
