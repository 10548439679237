@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.company {
  background-color: $c-company-bg;
  padding: 7px 14px 5px 21px;
  transition: background-color $t-default;
  align-items: center;

  &:focus,
  &:hover {
    background-color: $c-company-bg-hover;

    .company__link {
      visibility: visible;
    }
  }
}

.company-info__wrapper {
  overflow: hidden;
  width: 100%;
}

.company__avatar {
  @include size(29px);
  margin-right: 17px;
}

.company__name {
  font-size: 12px;
  line-height: 15px;
  color: $c-company-name;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 90%;
}

.company__additional {
  font-size: 10px;
  color: $c-gray-1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.company__link {
  font-size: 10px;
  line-height: 20px;
  color: $c-company-list-link;
  visibility: hidden;
  transition: none;
  white-space: nowrap;
  margin-bottom: 1px;
}
