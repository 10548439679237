@import 'src/shared/styles/variables';

.settings-form__title-block {
  font-size: 12px;
  line-height: 16px;
  color: $c-black;
  border-bottom: 1px solid $c-gray;
  padding-bottom: 13px;
  margin-bottom: 27px;
}
