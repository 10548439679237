@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.form {
  border: 1px solid $c-dark-grey;
  border-radius: 10px;
  margin: 0 auto;
  padding: 32px;
  width: 100%;
  max-width: 355px;

  @include media-breakpoint-up(md) {
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    max-width: 370px;
  }
}

.fields-container {
  margin-bottom: 39px;
}
