@import 'src/shared/styles/variables';

.contact-action__wrapper {
  .dropdown-button__menu-item {
    display: flex;
    padding-left: 11px;
    margin-bottom: 2px;

    svg {
      margin-right: 11px;
    }

    &:last-child {
      margin-top: 2px;
      margin-bottom: 0;
      padding-top: 2px;
      padding-bottom: 5px;
    }
  }

  .show.dropdown {
    .dropdown-button__menu {
      top: 1% !important;
      left: 49px !important;
      z-index: $z-contact-action-button;
    }
  }

  .contact-action__button {
    fill: $c-transaction-card-actions-icon;
    margin-top: 3px;
    padding: 8px 13px 4px 14px;
    border-radius: 2px;

    &:hover {
      background: $c-transaction-card-actions-bg;
    }
  }

  .icon {
    > path {
      fill: $c-black;
    }
  }
}
