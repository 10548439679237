$c-black: #1d191f;
$c-black-2: #000;
$c-black-3: #1d191f;
$c-white: #fff;
$c-gray-1: #979797;
$c-gray-2: #979797;
$c-gray-3: #f3f4f6;
$c-gray-4: #7f8fa4;
$c-gray-5: #7b7b7b;
$c-gray-6: #f3f3f3;
$c-gray-7: #dbdbdb;
$c-gray-8: #dbdbdb;
$c-gray-9: #dfe1eb;
$c-gray-10: #c2c6d1;
$c-gray-12: #c1c4d5;
$c-dark-grey: #333;
$c-dark-grey-1: #dbdbdb;
$c-gray-11: #6c6c6c;
$c-light-gray: #e7e5e4;
$c-light-gray-1: #cfd2db;
$c-light-gray-2: #f6f6f6;
$c-light-gray-4: #e3e4ea;
$c-light-gray-5: #e3e3e3;
$c-light-gray-6: #dfe1f2;
$c-light-gray-7: #dfe1fa;
$c-light-gray-8: #fafafa;
$c-light-gray-9: #e5e5e5;
$c-light-gray-10: #6f6e70;
$c-light-gray-11: #929ab0;
$c-light-gray-12: #f9f9f9;
$c-yellow: #fff86d;
$c-light-yellow: #f1efd4;

$c-deep-blue: #0151b9;
$c-blue: #005fda;
$c-svg-blue: #0053f8;
$c-dark-blue: #333;
$c-dark-blue-2: #062881;
$c-light-blue: #c8d4f2;
$c-light-blue-1: #cddaff;
$c-light-blue-2: #f2f6ff;
$c-light-blue-3: #c8dffa;
$c-light-blue-4: #d7e1fa;
$c-light-blue-5: #98b3f7;
$c-gray: #e7e5e4;
$c-sky-blue: #c4eef5;
$c-pink: #ff9fe0;
$c-orange: #f0af4a;
$c-purple: #a075c4;
$c-purple-2: #8388ab;
$c-green: #00c39e;
$c-green-2: #1c9479;
$c-light-green: #e0ffea;
$c-light-green-2: #c3ead8;
$c-light-green-3: #cbe9da;
$c-red: #eb5757;
$c-shadow-red: rgba(235, 87, 87, 0.2);
$c-dark-red: #c31717;
$c-light-red: #ffdbdb;
$c-light-red-2: #ffb3b3;

$font-euclid: 'ESRebondGrotesque';
$font-inter: 'ABCWhyte';
$font-fallback: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";

$max-container-width: 1440px;
$grid-gutter-widths: (
  xs: 30px,
  md: 30px,
  lg: 20px,
);

$bg-color: $c-white;
$bg-private-color: $c-gray-6;
$text-color: $c-black;
$text-footer-color: #979797;
$text-footer-privacy-color: $c-gray-5;
$headings-color: $c-black;
$nav-color: $c-gray-1;
$nav-active-color: $c-black;
$accent-color: $c-blue;
$accent-color-vivid: $c-light-blue;
$accent-color-dark: $c-deep-blue;
$shadow-color: #c3cbcd;
$shadow-color-dark: #bac4c7;
$c-toast-shadow: rgba($c-black, 0.1);
$font: #{$font-euclid}, #{$font-fallback};
$font-complementary: #{$font-inter}, #{$font-fallback};

$button-color: $c-blue;
$button-hover-color: $c-deep-blue;
$button-text-color: $c-white;
$password-switch-color: $c-blue;
$c-button-secondary-outline: $c-light-blue-3;
$dropdown-button: $c-dark-grey;
$dropdown-button-menu-bg: $c-white;
$dropdown-button-active-item-bg: #f0f4ff;
$dropdown-button-divider: #f3f3f3;

$c-danger-button: #eb5757;

$input-bg: $c-white;
$input-color: $c-black;
$input-border: #c8d4f2;
$input-bg-hover: $c-white;
$input-bg-focus: $c-white;
$input-border-focus: $c-blue;
$input-placeholder: $c-light-blue-4;
$input-border-error: $c-red;
$input-error: $c-red;

$c-select-input: $c-black;
$c-select-input-bg: $c-white;
$c-select-input-border: $c-light-blue;
$c-select-input-placeholder: $c-light-blue-4;
$c-select-input-active-option-bg: #f0f4ff;

$c-public-layout-title: $c-dark-grey;
$c-public-layout-email-title: $c-black;

$c-one-time-code-input: $input-border;
$c-one-time-code-input-focus: $c-blue;

$c-entry-subtitle-with-link: $c-black;
$c-entry-subtitle: $c-black;
$c-entry-input-label: $c-dark-grey;

$c-header-border: $c-black;

$error-color: $c-red;
$success-color: $c-green;
$label-required-color: $c-red;
$checkbox-label-color: $c-gray;

$company-logo: #dbdbdb;
$active-company-logo: $c-dark-grey;

$avatar-change-button: $accent-color;
$avatar-change-button-hover: $accent-color-vivid;

$settings-header-underline: $c-light-gray-1;
$settings-separator-color: $c-light-gray-1;
$settings-separator-active-color: $c-deep-blue;
$settings-input-bg: $c-light-gray-2;
$settings-field-required: $c-red;
$settings-menu-active-bg: $c-light-gray-12;
$c-location-label: $c-gray;
$confirm-number: $c-red;
$c-setting-connected-account-border: $c-light-gray-1;
$c-setting-payment-method-item: $c-light-gray-8;
$c-setting-payment-method-status-account: rgba($c-light-blue-4, 0.5);
$c-settings-change-password: $c-blue;
$c-settings-send-code: $c-blue;

$places-dropdown-bg: $c-light-gray-2;
$places-active-item-bg: $accent-color;
$places-active-item: $c-white;
$textarea-letters-counter: $c-dark-grey;

$modal-overlay: #494949;
$modal-body-bg: $c-white;
$transaction-modal-body-bg: transparent;

$close-button: $c-dark-blue;
$close-button-hover: $accent-color-vivid;

$outline: $c-black;

$c-error-field: $c-red;
$amount-input-bg: $c-light-gray-2;
$amount-input-text: $c-dark-grey;
$accounts-item-number-text: $c-gray;
$bank-accounts-list-wrapper-bg: $c-light-gray-2;
$radio-input-bg: $c-deep-blue;
$radio-input-bg-hover: $c-blue;
$account-projected-balance-color: $c-light-green;
$account-projected-balance-color-negative: $c-red;

$pill-chart-receivable: $c-green;
$pill-chart-payable: $c-red;

$transfer-status-bg: rgba($c-light-gray-1, 0.3);
$transfer-status-pending: $c-yellow;
$transfer-status-denied: $c-red;
$transfer-status-completed: $c-green;
$transfer-amount-add: $c-green;
$transfer-amount-subtract: $c-red;
$transfer-bg-hover: rgba($c-light-gray-1, 0.25);

$banner-title: $c-black;
$banner-text: $accent-color;

$c-toast: $c-black;
$c-toast-link: $c-blue;

$c-transaction-type: $c-green;
$c-transaction-pay-button: $c-white;
$c-transaction-type-payable: $c-red;
$c-transaction-number: $c-gray;
$transaction-pay-button-bg: $c-deep-blue;
$transaction-tag: $c-blue;
$transaction-details-bg: $accent-color;
$transaction-type-bg: $c-light-green;
$transaction-type-payable-bg: $c-light-red;
$transaction-details-text: $c-white;
$card-shadow: rgba($c-black, 0.5);
$card-bg: linear-gradient(124deg, $c-blue, $c-deep-blue 89%);
$card-separator: $c-white;

$c-accounting-tab: $c-gray-1;
$c-accounting-tab-active: $c-black;
$c-accounting-tab-border-active: $c-black;
$c-accounting-tabs-border: $c-black;

$c-accounting-tools-icon: $c-black;

$c-transaction-history-bg: $c-white;

$c-list-search-border: #cddaff;

$c-scrollbar-bg: #c2c6d1;
$c-scrollbar-track-bg: #f9f9f9;
$c-scrollbar-thumb: #c2c6d1;

$c-table-header: #bbbecf;
$c-table-header-border: #dbdbdb;
$c-table-header-active: #005fda;
$c-table-row: $c-purple-2;
$c-table-row-active: #f9f9f9;

$c-completed-due-date-bg: $c-light-green;
$c-completed-due-date-text: $c-green;
$c-close-to-date-due-date-bg: #ffede0;
$c-close-to-date-due-date-text: #ffbe5b;
$c-overdue-due-date-bg: $c-light-red;
$c-overdue-due-date-text: $c-red;

$c-search-icon: $c-gray;
$c-search-placeholder: $c-purple-2;
$c-search-border: $c-light-blue-1;
$c-search-border-hover: $c-blue;
$c-search-bg: $c-green;
$c-search-title: $c-white;
$c-search-input-icon: $c-light-blue-4;
$c-search-input-border-hover: $c-blue;
$c-search-input-bg: $c-white;
$c-search-action-title: $c-black;
$c-search-action-item-icon: $c-blue;
$c-search-action-item-title: $c-black;
$c-search-action-item-bg: $c-white;
$c-search-action-item-text: $c-purple-2;
$c-search-action-item-shadow: $c-light-gray-5;

$company-bg-hover: #f9f9f9;
$company-border: rgba($c-light-gray-1, 0.5);
$company-profile-text-area-border: $c-light-gray-6;
$company-shadow: rgba($c-black, 0.15);
$company-name: $c-black;
$company-username: $accent-color;
$c-company-bg: $c-white;
$c-company-bg-hover: #f0f4ff;
$c-company-name: $c-dark-grey;
$c-company-additional: $c-purple-2;

$c-company-list-bg: $c-white;
$c-company-list-border: $c-gray-7;
$c-company-list-new-business: $c-dark-grey;
$c-company-list-link: $c-blue;

$company-profile-text-area-border: $c-light-gray-1;
$company-type: $accent-color;
$company-type-bg: rgba($accent-color, 0.2);
$company-avatar-border: $c-gray-1;
$c-company-description-bg: $c-white;
$c-company-description-border: $c-gray-7;

$transaction-list-columns-titles: $c-light-gray-1;
$transaction-hover: rgba($c-light-gray-1, 0.25);
$transaction-company-name: $c-black;
$transaction-due-mobile: $accent-color;
$transaction-line: $c-light-gray-1;

$unverified-mark: $c-red;

$calendar-text: $c-black;
$calendar-text-hover: $c-white;
$calendar-tile-bg: rgba($c-light-gray-1, 0.5);
$calendar-tile-bg-hover: $accent-color;
$calendar-bg: $c-white;
$calendar-bg-hover: $accent-color;
$calendar-tile-mark: $accent-color;
$calendar-tile-mark-hover: $c-white;
$calendar-tile-shadow: rgba($c-gray, 0.5);
$calendar-arrow: $accent-color;
$calendar-navigation-hover: $accent-color-vivid;
$calendar-days: $c-gray-4;
$calendar-neighboring-month-tile: rgba($c-black, 0.5);
$calendar-neighboring-month-tile-bg: rgba($accent-color, 0.15);

$dropdown-menu-bg: $c-white;
$dropdown-menu: $c-dark-grey;
$dropdown-menu-shadow: rgba($c-black, 0.1);
$dropdown-menu-border: $c-light-gray-1;
$dropdown-menu-item-bg: transparent;
$dropdown-menu-item-bg-active: $c-gray-3;

$c-document-border: $c-light-gray-1;

$c-app-loading: $accent-color;

$c-integrations-tooltip-bg: $c-gray-3;
$c-integrations-tooltip-text: $c-black;

$c-business-logo-border: $c-light-gray-1;
$c-business-small-logo-border: $c-light-gray-4;

$c-collapse-bg: $c-white;
$c-collapse-title: $c-black;
$c-notifications-bg: $c-collapse-bg;

$c-active-entity-card-bg: #f9f9f9;
$c-entity-card-text: #333;
$c-entity-card-help-text: #979797;
$c-entity-logo-icon-bg: $c-yellow;
$c-entity-logo-icon-border: #f4f3f6;
$c-entity-logo-border: #dbdbdb;

$c-payable-amount-bg: #ffdbdb;
$c-payable-amount-text: #eb5757;
$c-receivable-amount-bg: #e0ffea;
$c-receivable-amount-text: #5abb7a;

$c-unverified-account: $c-gray;
$c-verified-account: $c-yellow;

$c-datepicker-calendar-bg: $c-white;
$c-datepicker-calendar-border: #c4c4c4;
$c-datepicker-calendar-day-name: $c-blue;
$c-datepicker-calendar-day: $c-dark-grey;
$c-datepicker-calendar-selected-day: $c-red;
$c-datepicker-calendar-disabled-day: #f2f2f2;
$c-datepicker-calendar-inactive-day: #bdbdbd;
$c-datepicker-border: $c-light-blue;
$c-datepicker: $c-black;
$c-datepicker-placeholder: $c-light-blue;
$c-datepicker-active: $c-purple-2;

$c-transaction-card-footer-border: $c-gray-8;
$c-transaction-card-footer-help-text: $c-purple-2;
$c-transaction-card-actions-icon: #c1c4d5;
$c-transaction-card-actions-bg: #f0f2f9;

$c-scheduled-invoice: $c-purple-2;
$c-completed-invoice: $c-green;
$c-coming-up-invoice: $c-orange;
$c-overdue-invoice: $c-red;

$c-transaction-card-bg: $c-white;
$c-transaction-card-shadow: #c3cbcd;
$c-transaction-card-entity-name: $c-black;
$c-transaction-card-border: $c-gray-8;
$c-transaction-card-breadcrumbs: #535353;
$c-transaction-card-active-breadcrumbs: $c-blue;
$c-transaction-card-edit: $c-blue;
$c-transaction-card-footer-bg: #eff4ff;

$c-short-list-btn-bg: #e7e5e4;
$c-short-list-btn-icon: #979797;
$c-short-list-active-btn-border: $c-gray-9;
$c-short-list-active-btn-hover: $c-red;

$c-accounting-dashboard-counter: #161c1c;
$c-accounting-dashboard-bg: $c-white;
$c-accounting-dashboard-title: $c-black;
$c-accounting-dashboard-link: $c-blue;

$c-tooltip-bg: #333;
$c-tooltip: $c-white;

$c-step-counter: $c-blue;

$c-form-title: $c-dark-grey;
$c-form-shadow: $c-light-gray-5;
$c-form-border: $c-dark-grey-1;

$c-block-border: $c-dark-grey-1;

$c-invoice-owner-email: $c-purple-2;

$c-add-bill-form-icon: $c-blue;
$c-add-bill-form-bg: $c-white;
$c-add-bill-form-border: $c-gray-8;
$c-add_bill-form-label: $c-black;
$c-add-bill-company-list-shadow: 0px 4px 45px $c-light-gray-5;
$c-add-bill-form-cancel-bg: $c-white;
$c-add-bill-form-cancel-btn: $c-blue;
$c-add-bill-form-cancel-btn-border: $c-blue;
$c-add-bill-form-cancel-btn-hover: $c-deep-blue;
$c-add-bill-form-submit-btn: $c-white;
$c-add-bill-form-submit-bg: $c-blue;
$c-add-bill-form-submit-hover: $c-deep-blue;
$c-add-bill-form-btn-disabled: $c-gray;
$c-add-bill-textarea-counter: $c-gray;
$c-add-bill-input-invalid: $c-red;
$c-add-bill-new-vendor-bg: rgba($c-light-blue, 0.5);
$c-add-bill-new-vendor: #929ab0;
$c-add-bill-email-input-disabled: rgba($c-purple-2, 0.5);

$c-add-bill-review-header: $c-black;
$c-add-bill-review-vendor-name: $c-black;
$c-add-bill-review-verify-icon: $c-gray;
$c-add-bill-review-vendor-email: $c-purple-2;
$c-add-bill-review-vendor-amount: $c-black;
$c-add-bill-review-vendor-dollar: $c-blue;
$c-add-bill-review-data-title: $c-dark-blue;
$c-add-bill-review-data-value: $c-black;
$c-add-bill-review-border: $c-gray-8;
$c-add-bill-review-default-logo-bg: $c-orange;

$c-checkbox-switcher-track: $c-dark-grey-1;
$c-checkbox-switcher-track-checked: $c-light-blue-5;
$c-checkbox-switcher-knob: $c-white;
$c-checkbox-switcher-knob-checked: $c-deep-blue;
$c-checkbox-switcher-box-shadow: 0px 0.1px 0.3px rgba($c-black-2, 0.1), 0px 1px 2px rgba($c-black-2, 0.2);

$z-header: 3;
$z-settings-field: 1;
$z-settings-header: 2;
$z-banner-wrapper: 1;
$z-banner-container: 3;
$z-modal: 10;
$z-modal-logo: 4;
$z-search-form: 1;
$z-app-loading: 4;
$z-app-loading-overlay: 5;
$z-app-loading-spinner: 6;
$z-scrollbar-track: 3;
$z-scrollbar-thumb: 4;
$z-invoice-details-card: 1;
$z-cropper: 11;
$z-notFound: 12;
$z-search-clear-button: 4;
$z-search-company-list: 1;
$z-tooltip: 8;
$z-transaction-card: 10;
$z-autocomplete-input: 1;
$z-simplebar-mask: 2;
$z-contact-action-button: 1;
$z-calendar: 2;

$t-default: 0.3s;
$t-select: 0.1s;
$t-input-color: $t-default;
$t-close-button: $t-default;
$t-settings-field-border: $t-default;
$t-settings-field-header: $t-default;
$t-checkbox-bg: 0.2s;
$t-app-loading: 1.8s;
$t-marquee: 10s;

$c-logo-border: $c-gray-1;

$c-invoice-table-border: $c-light-gray-1;
$invoice-message-bg: $c-light-gray-2;

$c-attachments-border: $c-light-gray-1;

$loader-items: 8;

$c-verification-form-bg: $c-white;
$c-verification-public-layout: $c-light-gray-9;
