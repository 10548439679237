@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.payable-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;

  .datepicker-field {
    margin-bottom: auto !important;
    max-width: 299px;

    > p:first-child {
      margin-bottom: 2px;
      transform: translateY(-4px);
    }
  }

  .payment-prompt {
    position: absolute;
    font-size: 9px;
    line-height: 11px;
    color: $c-gray-1;
    width: 100px;
    left: 105px;
  }

  .invoice-pay-btn {
    min-width: 99px;
    transform: translateY(1px);
  }

  // TODO: remove this class after the link is clickable
  .link__button {
    color: $accent-color;
    font-size: 10px;
    margin: 0;
    padding: 0;
    cursor: not-allowed;
  }
}
