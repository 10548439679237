@import 'src/shared/styles/variables';
@import '~bootstrap/scss/bootstrap-grid';

.payable-receivable-content {
  display: flex;
  flex-direction: column;
  background: $c-white;
  padding: 17px 0 15px 26px;
  border-radius: 10px;
  transition: box-shadow 0.2s;
  border: 1px solid $c-block-border;

  &:hover {
    box-shadow: 0 4px 45px $c-light-gray-5;
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    flex-basis: 48%;
  }
}

.payable-receivable__title {
  font-size: 12px;
  line-height: 16px;
  color: $c-black;
}

.payable-receivable__amount {
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 4px;

  &:first-letter {
    color: $c-green;
  }
}

.payable-receivable__aging {
  font-size: 12px;
  line-height: 15px;
  color: $c-gray-1;
}
