@import 'src/shared/styles/variables';

.notifications-container {
  border-radius: 10px;
  border: 1px solid $c-block-border;
  padding: 24px 24px 48px;
  max-width: 762px;
  margin: 0 auto;
}

.notifications-title {
  margin-bottom: 48px;
}

.notifications__message {
  font-size: 16px;
}

.notifications__help-text {
  font-size: 13px;
  margin-top: 8px;
}

.short-card {
  padding: 12px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.logo {
  height: 40px;
  width: 40px;
}

.scrollbar {
  height: 640px;
  padding-right: 16px;
}
