@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.header {
  z-index: $z-header;
  background: $c-white;
  min-height: 55px;
  position: sticky;
  top: 0;

  .header__wrapper {
    display: flex;
    position: relative;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }

    &::after {
      bottom: -2px;
      content: '';
      position: absolute;
      border-bottom: 0.5px solid $c-header-border;
      width: calc(100% - 40px);

      @include media-breakpoint-up(lg) {
        width: 83%;
      }
    }
  }

  .header__logo {
    transform: translateY(1px);

    @include media-breakpoint-down(sm) {
      margin-right: 30px;
    }
  }
}

.logo-wrapper {
  display: flex;
  padding-top: 18px;

  .logo-img {
    margin-right: 9px;
  }

  & > .logo-subtitle {
    padding-top: 4px;
    font-family: $font;
    font-size: 12px;
    line-height: 15px;
    color: $c-dark-grey;
  }
}

.navigation-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 12px;

  .links-wrapper {
    margin-right: 42px;

    .item-link {
      font-size: 12px;
      line-height: 15px;
      color: $c-black;
      text-decoration: none;
      padding-top: 1px;
    }
  }
}

.join-zuro-btn {
  padding-right: 17px;
  padding-left: 17px;
  margin-right: 2px;

  @include media-breakpoint-down(sm) {
    margin-top: 25px;
    margin-bottom: 20px;
  }
}
