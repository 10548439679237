@import '~bootstrap/scss/bootstrap-grid';

.form {
  max-width: 377px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: 377px;
  }
}

.form__title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 23px;
}

.form__rows {
  margin-bottom: 28px;
}

.form__controls {
  margin-top: 32px;
}

.address__container {
  margin-top: 34px;
}

.address__btn {
  margin-right: 12px;
}

.form__hidden {
  height: 1px;
  width: 1px;
  visibility: hidden;
  position: absolute;
  top: -1px;
  clip: rect(0 0 0 0);

  & button {
    display: none;
  }
}
