@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.field-wrapper {
  color: $text-color;

  &--disabled {
    opacity: 0.7;
  }
}

.field-label {
  cursor: pointer;
  align-items: center;
  display: flex;
  color: inherit;
  font-size: 10px;
  line-height: 15px;
  margin: 0;

  &:hover {
    .checkbox-input:not(:checked) ~ .checkbox-control {
      border-color: $c-dark-grey;

      &::after {
        background-color: $accent-color-vivid;
      }
    }
  }
}

.checkbox-input {
  display: none;

  &:checked ~ .checkbox-control {
    &::after {
      @include size(9px);
      position: relative;
      content: '';
      display: block;
      background-color: $c-dark-grey;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 15 15'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
  }
}

.checkbox-control {
  @include size(11px);
  border: solid 1px $c-dark-grey;
  background-color: transparent;
  margin-right: 9px;
  transition: border-color $t-checkbox-bg ease-in;
  border-radius: 3px;
}
