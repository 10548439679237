@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.transfer-funds__wrapper {
  max-width: 100%;

  @include media-breakpoint-up(sm) {
    width: 429px;
    min-height: 405px;
  }
}

.transfer-funds__step-title {
  padding-left: 2px;
}

.success-message-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    min-width: 429px;
    min-height: 351px;
  }
}

.amount__wrapper {
  margin-top: 45px;
  margin-bottom: 6px;
}

.amount-input__wrapper {
  margin: 0;
  max-width: fit-content;
}

.helper-amount-span {
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;

  @include media-breakpoint-down(xs) {
    max-width: 190px;
  }
}

.amount-input {
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  height: 44px;
  background-color: $c-white;

  padding: 0;
  max-width: 300px;
  border: none;

  &:hover {
    background-color: $c-white;
  }
}

.amount-prefix {
  font-weight: 500;
  color: $c-green;
  font-size: 35px;
  line-height: 44px;
}

.transfer-success-message {
  margin-top: 34px;
  font-weight: bold;
  font-size: 30px;
  line-height: 27px;
  color: $c-black;
}

.success-img {
  fill: $c-green;
}
