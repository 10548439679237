@import 'src/shared/styles/variables';

.password-reset-link {
  position: absolute;
  top: -14px;
  right: 0;
  text-transform: none;
  font-size: 10px;
}

.password-input-wrapper {
  position: relative;

  input {
    padding-right: 50px;
  }

  .password-switch {
    background: none;
    font-size: 14px;
    font-weight: 300;
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    position: absolute;
    right: 2px;
    top: 6px;
    letter-spacing: -0.7px;
  }
}

// hide 'eye' from a password input in Microsoft Edge
input[type=password]::-ms-reveal {
  display: none;
}
