@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.wrapper {
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 400px;
  margin: 0 auto;
}

.title {
  font-size: 26px;
  font-weight: 500;
  margin: 0 0 20px;
}

.amount-input__wrapper {
  margin-bottom: 25px;
  min-width: 170px;
}

.amount-input {
  border: 0;
  padding: 11px 20px;
  border-radius: 6px;
  background-color: $amount-input-bg;
  color: $amount-input-text;
  max-width: 170px;

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}
