@import '~bootstrap/scss/bootstrap-grid';

.unlock-account-form__container {
  @include media-breakpoint-up(lg) {
    padding-bottom: 58px;
  }
}

.submit-button {
  width: 186px;
}
