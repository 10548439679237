@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.confirm-disconnect-title {
  margin-top: 13px;
  margin-bottom: 0;
  font-weight: 700;
  max-width: 220px;
}

.confirm-disconnect-content {
  margin-bottom: 18px;
  padding-bottom: 0;
}
