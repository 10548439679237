@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.transaction-card-footer__wrapper {
  margin-top: auto;
  position: relative;
}

.transaction-card-footer__inner {
  padding-top: 9px;
  border-top: 0.5px solid $c-transaction-card-footer-border;
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transaction-card-footer__help-text {
  font-size: 10px;
  line-height: 13px;
  color: $c-gray-1;
  margin-bottom: 0;
  margin-top: 3px;
  padding-left: 2px;
}
