@import 'src/shared/styles/variables';

.payment-setting__wrapper {
  & > button {
    margin-top: 15px;
    width: 220px;
  }
}

.info__wrapper {
  margin-bottom: 22px;
}

.subtitle {
  font-size: 12px;
  line-height: 15px;
}

.text-info {
  margin: 0 0 0 8px;
}

.title {
  padding-bottom: 12px;
  margin-bottom: 21px;
}
