@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.toast__icon {
  transform: translateY(-1px);
  overflow: visible !important;
}

.toast__title {
  font-weight: 600;
  margin: 0 10px 0 0;
  color: $c-toast;
  padding-left: 12px;
  flex-shrink: 0;
  white-space: nowrap;
}

.toast__message {
  margin: 0;

  > a {
    position: relative;
    color: $c-toast-link;

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      background: $c-toast-link;
      height: 1px;
      left: 0;
      right: 0;
      bottom: -2px;
    }
  }
}
