@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.field-wrapper {
  &--disabled {
    opacity: 0.7;
  }
}

.field-label {
  cursor: pointer;
  align-items: center;
  display: flex;
  color: $text-color;
  font-size: 13px;
  margin: 0;

  &:hover {
    .radio-input:not(:checked) ~ .radio-control {
      border-color: $accent-color-vivid;

      &::after {
        background-color: $accent-color-vivid;
      }
    }
  }
}

.radio-input {
  display: none;

  &:checked ~ .radio-control {
    &::after {
      background-color: $radio-input-bg;
    }
  }
}

.radio-control {
  @include size(12px);
  border-radius: 50%;
  border: solid 1.5px $radio-input-bg;
  background-color: transparent;
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.2s ease-in;

  &::after {
    @include size(6px);
    content: '';
    display: block;
    border-radius: 50%;
    transition: background-color 0.2s ease-in;
  }
}
