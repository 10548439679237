@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.header {
  display: flex;
  padding-left: 3px;
}

.header__logo {
  margin-right: 24px;
  margin-left: 1px;
  margin-top: 3px;
}

.header__general-company-info-row {
  display: flex;
  flex-direction: row;
}

.header__general-company-info-column {
  display: flex;
  flex-direction: column;
}

.header__container {
  display: flex;
  flex-direction: column;
}

.header__title {
  display: flex;
  align-items: center;
  margin-top: 4px;
  line-height: 20px;
  font-weight: bold;
  font-size: 16px;
  color: $c-black;

  & > .verification_star {
    margin-left: 11px;
  }
}

.header__handle {
  margin: 4px 0 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: $c-gray-1;

  @include media-breakpoint-down(sm) {
    font-size: 13px;
    margin-bottom: 4px;
  }
}

.header__location {
  margin: 6px 0 0;
  font-size: 12px;
  line-height: 15px;
  color: $c-black;

  @include media-breakpoint-down(sm) {
    font-size: 10px;
  }
}

.header__internal-profile {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  top: 141px;
  padding-left: 24px;
  width: 100%;
  z-index: 1;

  .header__logo {
    border: 4px solid $c-gray-6;
    margin-left: 0;
  }

  .header__title {
    margin-top: 11px;
    height: 23px;

    & > .verification_star {
      margin-top: 1px;
      margin-left: 14px;
      width: 20px;
      height: 20px;
      transform: translateY(3px);
    }

    & > .header__button-container {
      margin-top: 5px;
      margin-left: 9px;
    }
  }

  .header__container {
    padding-left: 5px;
  }

  .header__link {
    margin-top: 3px;
    margin-left: 1px;
    font-size: 12px;
  }

  .link-btn {
    margin-bottom: 2px;
    width: 122px;
    transform: translate(1px, -1px);
    justify-content: space-between;
    white-space: nowrap;

    @include media-breakpoint-down(xs) {
      margin-top: 10px;
      margin-left: 5px;
    }
  }

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.create-invoice-link-btn {
  padding-left: 9px;
}

.add-bill-link-btn {
  padding-left: 12px;
}

.create-invoice-btn-icon {
  margin-left: -4px;
}

.add-bill-btn-icon {
  margin-right: 2px;
}
