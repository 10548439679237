@import '~bootstrap/scss/bootstrap-grid';

.button {
  width: 100%;

  @include media-breakpoint-up(lg) {
    min-height: 30px;
    min-width: 89px;
    width: fit-content;
  }
}
