.field__row {
  display: grid;
  grid-template-columns: 1fr 282px;
  grid-column-gap: 12px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.field__title {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 13px;
}

.field__title--big {
  font-size: 15px;
}

.field__subtitle {
  font-size: 12px;
}

.autocomplete__field {
  margin-top: 4px;
}

.field__subtitle--verification {
  line-height: 1.3;
}

.field__row--verification {
  margin-bottom: 12px;
}
