@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.contacts-table__wrapper {
  background: $c-transaction-history-bg;
  border-radius: 10px;
  border: 1px solid $c-block-border;
  padding: 23px 12px 15px 18px;
  width: 100%;
  height: 696px;

  @include media-breakpoint-up(sm) {
    height: 643px;
  }

  .data-grid-table__header {
    & > div {
      &:first-child {
        padding-left: 49px;
      }
    }
  }
}

.contacts-table__header {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }
}

.contacts-search__wrapper {
  padding-left: 13px;
  margin-bottom: 0;
}

.contacts-search__input {
  @include media-breakpoint-up(sm) {
    width: 209px;
  }
}

.contacts-table__row {
  cursor: pointer;
  padding-bottom: 4px;

  > div {
    padding-bottom: 5px;
  }
}

.contacts-amount {
  padding-left: 15px;
  margin-bottom: 20px;
}

.add-contact {
  margin: 25px 0 15px 13px;

  @include media-breakpoint-up(sm) {
    padding: 5px 19px;
    margin: 0;
  }
}
