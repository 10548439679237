@import 'src/shared/styles/variables';

.transfer-balance__wrapper {
  text-align: center;
  margin-bottom: 49px;
}

.transfer-balance {
  color: $c-purple-2;
  font-size: 15px;
  line-height: 19px;
}

.text-error {
  color: $c-red;
}
