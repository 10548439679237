@import '~bootstrap/scss/bootstrap-grid';

.invoice-form__body {
  margin-bottom: 33px;
}

.invoice-form__field {
  &:not(:last-child) {
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
      margin-bottom: 28px;
    }
  }
}

.invoice-form__input {
  padding: 14px 16px;
}

.invoice-form__textarea {
  min-height: 130px;
  padding: 14px 16px;
  font-size: 13px;
}

.invoice-form__textarea-label {
  padding-top: 1px;
}

.invoice-form__button {
  padding: 0 27px;
}
