@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.tabs {
  margin-top: 53px;
  margin-bottom: 27px;
  border-bottom: 1px solid $c-light-blue;
  width: 338px;

  @include media-breakpoint-down(sm) {
    width: auto;
  }
}

.left-column {
  padding-bottom: 68px;

  @include media-breakpoint-down(sm) {
    text-align: center;
    width: 100%;
  }

  form button:last-child {
    width: 104px;
    padding: 5px 49px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  form div {
    margin-bottom: 28px;
    width: 324px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
