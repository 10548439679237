@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.field-wrapper {
  margin-top: 3px;
  padding-left: 1px;
}

.image-crop__input {
  display: none;
}
.image-crop__label {
  color: $text-color;
  font-weight: 500;
  font-size: 16px;
  margin: 0;

  @include media-breakpoint-down(md) {
    font-size: 15px;
  }

  &--required {
    &:after {
      color: $label-required-color;
      content: '*';
      display: inline-block;
      font-size: 12px;
      padding-left: 3px;
      transform: translateY(-2px);
    }
  }
}

.image-crop__control {
  font-size: 12px;
  font-family: inherit;
  font-weight: 400;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-transform: capitalize;
  color: $accent-color;

  &:not(:disabled):hover {
    color: $accent-color-vivid;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.file__wrapper {
  display: flex;
  align-items: center;
  margin: 0 10px 5px 0;
}

.file__name {
  color: $accent-color;
  font-size: 15px;
  margin-right: 5px;
}

.remove-button {
  @include size(14px);
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 0.7;
  }
}

.crop-image--disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
