@import '~bootstrap/scss/bootstrap-grid';

.two-column {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 10px;

  @include media-breakpoint-up(sm) {
    padding: 0 20px;
    grid-template-columns: 1fr minmax(200px, 273px);
  }

  &:not(:last-child) {
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
      margin-bottom: 18px;
    }
  }
}
