@import 'src/shared/styles/variables';

.lock__wrapper {
  position: relative;
  margin-bottom: 22px;
}

.lock__head,
.lock__body {
  height: 73px;
  fill: $accent-color;
}

.lock__head {
  transform: translateY(10px);
  position: absolute;
  animation: headScale 2.2s ease-in-out forwards;
  animation-delay: 0.5s;
}

.lock__body {
  animation: scale 2.2s ease-in-out forwards;
  animation-delay: 0.5s;
}

@keyframes scale {
  45% {
    transform: scale(1.12);
  }

  60% {
    transform: scale(1.07);
  }

  80% {
    transform: scale(1.07);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes headScale {
  45% {
    transform: scale(1.12) translateY(10px);
  }

  60% {
    transform: scale(1.07) translateY(10px);
  }

  80% {
    transform: scale(1.07);
  }

  100% {
    transform: scale(1);
  }
}
