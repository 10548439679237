.btn-link {
  font-size: 13px;
  line-height: 20px;
  padding: 0 !important;
}

.additional-text {
  font-size: 13px;
  line-height: 20px;
}
