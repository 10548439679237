@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.payment-methods__actions-wrapper {
  .dropdown-menu {
    width: 205px;
    left: -35% !important;
    padding: 7px 6px 3px 3px;

    &:before {
      left: 62%;
    }
  }

  .dropdown-button__menu-item {
    padding-left: 11px;
    margin-bottom: 2px;

    img {
      width: 9px;
      height: 9px;
      margin-right: 11px;
    }

    &:last-child {
      margin-top: 2px;
      margin-bottom: 0;
      padding-top: 2px;
      padding-bottom: 5px;
    }
  }

  .dropdown-button__menu-item--disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }

  .payment-methods__actions-btn {
    fill: $c-transaction-card-actions-icon;
    margin-top: 3px;
    padding: 8px 13px 4px 14px;
    border-radius: 2px;

    &:hover {
      background: $c-transaction-card-actions-bg;
    }
  }
}
