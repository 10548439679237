@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.table__wrapper {
  overflow-x: auto;
}

.table__inner {
  min-width: 390px;
}

.data-grid-table__row {
  display: grid;
  align-items: center;
  padding: 8px 0 10px 14px;
  border-radius: 2px;
  color: $c-gray-1;
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 3px;
  -webkit-tap-highlight-color: transparent;

  &.data-grid-table__row--active,
  &:hover {
    background: $c-table-row-active;
  }

  > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 30px;

    @include media-breakpoint-down(md) {
      padding-right: 10px;
    }
  }
}

.data-grid-table__header {
  display: grid;
  margin-left: 14px;
  margin-bottom: 10px;
  padding-right: 15px;
}
