@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.short-list-header__wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid $c-gray-7;
  margin-bottom: 19px;

  > svg {
    margin-right: 11px;
  }

  .title {
    margin-right: 7px;
    font-size: 12px;
    line-height: 16px;
  }

  .quantity {
    color: $c-purple-2;
    font-size: 12px;
    line-height: 15px;
  }
}
