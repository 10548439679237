@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.loading__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fields__wrapper {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
  }
}

.form-input__wrapper {
  min-width: 170px;
}

.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
  margin-top: 40px;
}

.button {
  padding-right: 20px;
  padding-left: 20px;
  min-width: 135px;
  margin-left: 20px;
}

.field__label {
  color: $text-color;
  font-weight: 500;
  font-size: 13px;
  margin: 30px 0 5px;
}

.documents__wrapper {
  margin-bottom: 25px;
}

.documents-label {
  margin: 0;
}

.file-buttons__wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
}

.create-invoice-button {
  min-height: 21px;
  position: absolute;
  left: 90px;
}

.fileupload__button {
  margin-right: 25px;
}

.address-field {
  width: 100%;
  font-size: 13px;
}

.address-field__label {
  font-size: 10px;
  font-style: italic;
  color: $c-location-label;
  padding-top: 4px;
  display: flex;
  justify-content: flex-end;
}

.address-main-field {
  margin-top: 10px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;

  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
}

.address-secondary-field {
  display: grid;
  grid-template-columns: 0.7fr 0.8fr 0.4fr;
  grid-column-gap: 20px;

  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
}

.field__wrapper {
  margin-bottom: 0;
  width: 100%;
  min-width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.autocomplete {
  background-color: $input-bg;
  border: none;
  border-bottom: 1px solid $input-border;
  color: $text-color;
  font-family: inherit;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  padding: 4px 0;
  width: 100%;
  transition: all $t-input-color;

  &:hover,
  &:focus {
    border-bottom-color: $input-border-focus;
  }
}
