@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.collapse__wrapper {
  background: $c-collapse-bg;
  border-radius: 10px;
  padding: 22px 19px 45px;
  min-height: 273px;
  height: 385px;
  position: relative;
  overflow: hidden;
  border: 1px solid $c-block-border;

  .entity-short-card {
    margin-bottom: 4px;
  }
}

.collapse-button__wrapper {
  height: 60px;
  background: linear-gradient(180deg, rgba($c-collapse-bg, 0) -19.79%, $c-collapse-bg 68.48%);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 7px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 10px;
  line-height: 15px;

  @include media-breakpoint-up(sm) {
    padding-right: 7px;
  }
}

.collapse__title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $c-collapse-title;
  margin-bottom: 13px;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
