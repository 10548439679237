@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.warning-popup__text {
  font-size: 15px;
  padding-bottom: 10px;
  margin-top: 0;
  max-width: 315px;

  @include media-breakpoint-down(sm) {
    font-size: 13px;
  }
}

.warning-popup__controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & button {
    &:first-child {
      margin-right: 40px;

      @include media-breakpoint-down(xs) {
        margin-right: 20px;
      }
    }
  }
}

.hidden-element {
  display: none;
}
