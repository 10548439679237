@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.inputs-container {
  border-top: 0.5px solid $c-add-bill-form-border;
  border-bottom: 0.5px solid $c-add-bill-form-border;
  width: 100%;
  margin-top: 6px;
  padding: 5px 0 16px;

  @include media-breakpoint-up(sm) {
    padding: 26px 1px 16px 0;
  }
}

.add-bill-form__field {
  padding: 0;
  align-items: center;
}

.add-bill-form__textarea-field {
  align-items: flex-start;
  padding-top: 0;

  @include media-breakpoint-up(sm) {
    padding-top: 2px;
  }
}

.add-bill-form__label {
  margin: 7px 0;
  padding: 0;

  @include media-breakpoint-up(sm) {
    margin: 0;
  }
}

.add-bill-form__textarea-label {
  padding-top: 0;

  @include media-breakpoint-up(sm) {
    padding-top: 14px;
  }
}

.field__wrapper {
  margin-bottom: 0;
}

.vendor-inputs__container {
  position: relative;
}

.vendor-input {
  padding: 3px 0 6px 37px;
  height: 35px;
  border-radius: 5px;
  border-color: $input-border;

  &::placeholder {
    font-size: 12px;
  }
}

.vendor-input__new-vendor {
  padding-right: 90px;
}

.vendor-input__icon {
  @include size(14px);
  left: 15px;
  top: 10px;
  transform: none;
}

.new-vendor {
  width: 75px;
  height: 18px;
  position: absolute;
  right: 12px;
  top: 9px;
  background: $c-add-bill-new-vendor-bg;
  font-size: 10px;
  color: $c-add-bill-new-vendor;
  margin: 0;
  padding: 4px 6px 4px 9px;
  border-radius: 4px;
  text-align: center;
}

.new-vendor--hidden {
  display: none;
}

.textarea__wrapper {
  margin-top: 6px;
  position: relative;
}

.date-input {
  padding-top: 8px;
}

.email-input--disabled {
  color: $c-add-bill-email-input-disabled;
  opacity: 0.5;
}

.textarea {
  height: 107px;
  padding: 9px 11px 20px;
  border-radius: 5px;
  font-size: 13px;
}

.textarea-counter {
  position: absolute;
  right: 15px;
  bottom: 13px;
  font-style: initial;
  color: $c-dark-grey;
}

.company-list {
  width: 100%;
  padding: 0 3px;
}

.company-list__container {
  width: 100%;
  min-width: 270px;
  margin-top: 10px;
  padding: 10px 13px 0 0;
  position: absolute;
  right: 0;
  top: 35px;
  background-color: $c-company-list-bg;
  border-radius: 10px;
  display: none;
  flex-direction: column;
  text-align: initial;
  z-index: $z-search-company-list;
  box-shadow: $c-add-bill-company-list-shadow;

  @include media-breakpoint-down(md) {
    width: 100%;
    top: 55px;
  }
}

.company-list__container--active {
  display: flex;
  justify-content: space-between;
}

.company-list__container--hidden {
  height: 48px;
  justify-content: center;
  margin-top: 5px;
}

.scroll--hidden {
  padding-right: 0;

  .entity-short-card {
    margin-right: 0;
  }
}

.scrollbar {
  max-height: 290px;
  margin-bottom: 20px;
}

.scrollbar--hidden {
  display: none;
}

.new-business__wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $c-company-list-new-business;
  padding: 5px 0 15px 27px;
}

.buttons__container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-cancel {
  width: 97px;
  height: 30px;
}

.button-submit {
  width: 123px;
  height: 30px;
  margin-left: 20px;
}
