@import 'src/shared/styles/variables';

.address-form--light-theme {
  input {
    background-color: $c-white;
  }
}

.address-form__row {
  display: flex;
  justify-content: space-between;
}

.address-form__row-intent--default {
  margin-bottom: 12px;
}

.address-form__row-intent--extended {
  margin-bottom: 28px;
}

.address-form__field {
  margin-bottom: 0;
  min-width: auto;
  width: 100%;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.address-form__input {
  min-width: auto;
}
