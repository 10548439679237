@import 'src/shared/styles/variables';

.documents__wrapper {
  height: 279.2px;
  max-height: 279.2px;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: $c-scrollbar-thumb $c-scrollbar-track-bg;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $c-scrollbar-thumb;
  }
}
