@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.accounting-tools__wrapper {
  padding: 24px 28px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-height: 180px;
  box-sizing: border-box;

  @include media-breakpoint-down(sm) {
    min-height: 125px;
  }
}

.accounting-tools__title {
  margin-bottom: 17px;
  padding-left: 2px;
}

.accounting-tool__wrapper {
  &:not(:last-child) {
    margin-bottom: 19px;
  }
}

.accounting-tool__link {
  color: $c-black;
  display: flex;
  align-items: center;
  font-weight: 500;

  &:hover {
    color: $c-black;

    .accounting-tool__icon {
      fill: $c-black;
      stroke: $c-black;
    }
  }
}

.accounting-tool__icon {
  fill: $c-black;
  stroke-width: 0.5px;
  stroke: $c-black;
  flex: 0 0 auto;
  margin-right: 16px;
}

.accounting-tool__icon--export {
  margin-left: 3px;
  margin-right: 17px;
}
