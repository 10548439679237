@import '~bootstrap/scss/bootstrap-grid';

.recovery__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.button {
  display: inline-block;
  width: 121px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.field__wrapper {
  margin-bottom: 32px;
  position: relative;
  width: 100%;
  max-width: 370px;

  > label {
    font-size: 13px;
    margin-bottom: 5px;
    display: inline-block;
  }
}

.input__wrapper {
  > div {
    position: absolute;
  }
}
