@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.form-tfa {
  padding-bottom: 64px;
}

.form-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 223px;

  h3 {
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0;
    color: $c-dark-grey;

    @include media-breakpoint-up(sm) {
      white-space: nowrap;
    }
  }

  .subtitle {
    margin-top: 9px;
    margin-bottom: 0;
    font-size: 16px;
    font-family: $font-inter;
  }

  .email {
    margin-top: 0;
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 16px;
    font-family: $font-inter;
  }

  .checkbox__wrapper {
    margin-bottom: 30px;

    > label {
      font-size: 13px;
    }
  }

  .button-send-code {
    min-width: 121px;
    text-transform: capitalize;
    margin-bottom: 32px;
  }

  .resend-wrapper {
    font-family: $font-inter;
    color: $c-black;
  }

  .button-resend-code {
    font-family: $font-inter;
    font-size: 12px;
    line-height: 15px;
    padding: 0;
  }

  &:not(.form-column--phone) {
    .code-field__wrapper {
      > label {
        font-size: 15px;
      }
    }
  }
}

.form-column--phone {
  max-width: 325px;

  h3 {
    line-height: 1.1;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 21px;
  }

  .subtitle {
    margin: 0 0 22px;
    font-size: 13px;
  }
}

.button {
  max-width: 175px;
  margin-bottom: 20px;
  min-width: 50px;
}

.code-field__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 32px;
}

.code-field__label {
  font-size: 13px !important;
  line-height: 12px;
  margin-bottom: 24px;
}
