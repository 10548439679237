@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.company__wrapper,
.company__loading {
  min-height: 496px;
  width: 1028px;
  padding: 70px 280px;
}

.company__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.company__wrapper--form {
  padding: 60px 0 67px;
}

.company__error {
  font-size: 16px;
}

.company__header {
  margin-bottom: 28px;
  text-align: center;

  @include media-breakpoint-up(sm) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 33px;
  }
}

.company__avatar {
  margin: 0 auto 10px;
  flex: 0 0 auto;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
    margin-right: 30px;
  }
}

.company__inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.company__name {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 3px;

  @include media-breakpoint-up(sm) {
    font-size: 34px;
    margin-bottom: 0;
  }
}

.company__username {
  color: $c-black;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
}

.company__username--top {
  margin-bottom: 5px;

  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.company__username--bottom {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.company__number-clients {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.company__profile-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $c-black;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 3px;
}

.company__address {
  font-size: 12px;
}

.company__subtitle {
  color: $c-black;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 1;

  @include media-breakpoint-up(sm) {
    font-size: 22px;
    margin-bottom: 7px;
  }
}

.company__about,
.company__contact-info {
  margin-bottom: 25px;
  font-size: 13px;
  line-height: 1.2;

  @include media-breakpoint-up(sm) {
    font-size: 13px;
    margin-bottom: 30px;
  }
}

.company__contact-info {
  margin-bottom: 40px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 30px;
  }
}

.contact-info__text {
  margin: 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 70px;
  width: 468px;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  > button:first-child {
    margin-right: 12px;
  }
}

.back-button {
  margin-left: auto;
  display: flex;
  align-items: center;

  &:before {
    content: '\2190';
    margin-right: 5px;
    transform: translateY(-2px);
  }

  @include media-breakpoint-down(md) {
    margin-top: 25px;
    margin-left: 0;
  }
}

.company__type {
  position: absolute;
  font-size: 13px;
  line-height: 14px;
  color: $company-type;
  border-radius: 13px;
  background-color: $company-type-bg;
  padding: 4px 18px;
  top: 46px;
  right: 54px;
}

.company-details-layout__wrapper {
  position: relative;
}
