@import 'src/shared/styles/variables';

.content-block {
  border: 1px solid $c-dark-grey;
  background-color: $c-verification-form-bg;
  border-radius: 10px;
  padding: 32px;
}

.container {
  margin-top: 26px;
}

.description {
  max-width: 232px;
}

.continue-button {
  display: block;
  margin-top: 40px;
  margin-left: auto;
}

.add-icon-wrapper {
  margin-right: 17px;
}
