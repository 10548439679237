@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.bank-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
}

.bank-label {
  display: block;
  font-size: 13px;
  line-height: 12px;
  color: $c-dark-blue;
  margin-bottom: 19px;
  margin-left: 10px;
}

.bank__wrapper {
  display: flex;
  width: 95%;
  margin: 0 auto;

  color: $c-black;
  background-color: $c-gray;
  border-radius: 10px;

  padding: 15px 0 11px 22px;
}

.bank__info {
  display: flex;
  flex-direction: column;
  margin-left: 19px;
}

.bank-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 9px;
}

.bank-acct-number {
  font-size: 12px;
  line-height: 16px;
}
