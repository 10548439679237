@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.page__title {
  font-size: 30px !important;
  padding-left: 0 !important;
  padding-top: 5px;
  padding-bottom: 30px;
  font-weight: 400 !important;
}

.page__content {
  width: 100%;
  border-radius: 10px;
  background: $bg-color;
  padding: 30px 23px 20px 23px;
  min-height: 200px;
  max-width: 90vw;
  border: 1px solid $c-dark-grey-1;

  @include media-breakpoint-up(sm) {
    width: 480px;
    padding: 30px 35px 20px 33px;
  }

  &.confirmation-page,
  &.completed-page {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      width: 499px;
      padding-left: 43px;
      padding-right: 46px;
    }

    > div:first-child {
      transform: translateX(10px);
    }
  }

  &.confirmation-page {
    padding-top: 50px;
    position: relative;
    min-height: 481px;
    padding-bottom: 19px;
    margin: 10px 0 15px 0;

    .scheduled-label,
    .completed-label {
      position: absolute;
      width: 100%;
      height: 20px;
      background: $c-light-green;
      color: $c-green-2;
      font-size: 12px;
      line-height: 16px;
      top: 0;
      left: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      padding-left: 3px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .scheduled-label {
      color: $c-blue;
      background: $c-transaction-card-footer-bg;
    }

    .confirmation-page__wrapper {
      padding-top: 0;
    }
  }
}

.field_wrapper {
  &:first-child {
    margin-bottom: 16px;
  }

  &:nth-child(2) {
    margin-bottom: 23px;
  }
}

.invoice-details__wrapper {
  margin-bottom: 29px;
}

.payment-confirm-button {
  width: 117px;
  padding-left: 0;
  padding-right: 0;
  transform: translate(14px, 1px);
}

.download-invoice-button {
  width: 131px;
  transform: translateY(1px);
}

.payment-action {
  margin-bottom: 9px !important;
}

.payment-form-submit-button {
  width: 110px;
}

.unschedule-button {
  width: 110px;
  margin-left: 17px;
}

.download-button {
  min-width: 86px;
}
