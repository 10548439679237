@mixin center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  min-width: 0;
}
