@import 'src/shared/styles/variables';

.label {
  color: $c-entry-input-label;
  padding-top: 8px;
}

.label__text {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
}

.label__description {
  font-size: 10px;
  font-weight: 400;
}
