@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.form-title {
  display: block;
  font-size: 13px;
  line-height: 12px;
  font-weight: 500;
  margin-top: 26px;
  margin-bottom: 16px;
  color: $c-dark-grey;
}

.text-area__wrapper {
  margin-bottom: 87px;
}

.editable-content {
  border: 1px solid $input-border;
  height: 104px;
  width: 100%;
  padding: 9px 16px;
  border-radius: 5px;

  font-size: 13px;
  line-height: 16px;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: $c-scrollbar-track-bg;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $c-scrollbar-thumb;
  }
}
