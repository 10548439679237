@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.popup__content {
  max-width: 499px;
  min-height: 419px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  @include media-breakpoint-up(sm) {
    padding: 0 148px;
  }
}

.popup__title {
  font-weight: 700;
  margin: 24px 0;
  width: 100%;
  text-align: center;
}

.popup__subtitle {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 16px;
  width: 100%;
  text-align: center;
}

.popup__icon {
  fill: $c-blue;
}
