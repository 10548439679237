@import '~bootstrap/scss/bootstrap-grid';

.payable-receivable-container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include media-breakpoint-up(xs) {
    justify-content: center;
    flex-direction: column;
  }

  @include media-breakpoint-up(sm) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
    align-items: normal;
    flex-direction: column;
  }
}
