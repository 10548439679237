@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.form-column {
  min-width: 223px;
}

.button {
  max-width: 175px;
  margin: 0 auto;
}

.input-wrapper {
  width: 100%;
  margin: 0 auto 20px;

  @include media-breakpoint-up(md) {
    max-width: 300px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 400px;
  }
}
