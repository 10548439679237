@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.list-search__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 31px;
}

.list-search__input-wrapper {
  margin-bottom: 0;
  margin-right: 23px;
  max-width: 401px;
  min-width: 135px;

  @include media-breakpoint-down(sm) {
    margin-right: 10px;
  }
}

.list-search__input {
  border: 1px solid $c-list-search-border;
  border-radius: 5px;
  font-family: $font;
  padding-left: 8px;
}

.list-search__button {
  font-weight: 500;
}
