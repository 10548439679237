@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.one-time-code__container {
  display: flex;
  justify-content: center;
  width: 100% !important;

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }

  &.is-invalid {
    input[value=''] {
      box-shadow: inset 0 0 0 1px $input-border-error;
    }
  }

  input {
    font-family: $font-complementary;
    margin-right: 8px;
    border: none !important;
    border-radius: 5px !important;
    caret-color: transparent !important;
    box-shadow: inset 0 0 0 1px $c-one-time-code-input;
    transition: box-shadow 0.2s ease-out;

    &:last-child {
      margin-right: 0 !important;
    }

    &:focus {
      box-shadow: inset 0 0 0 1px $c-one-time-code-input-focus;
    }

    @include media-breakpoint-up(md) {
      margin-right: 13px;
    }
  }
}
