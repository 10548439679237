@import 'src/shared/styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid $c-form-border;
  margin-bottom: 30px;
}
