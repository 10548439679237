.contact-name__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-name {
  margin: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
