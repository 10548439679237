@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.entry-title__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
  }
}

.title {
  color: $c-public-layout-title;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 0;

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }
}

.subtitle {
  display: flex;
  color: $c-entry-subtitle-with-link;
  font-family: $font-complementary;
  font-size: 16px;
  margin-top: 8px;
}

.subtitle__text {
  font-family: $font-complementary;
  margin: 0 4px 0 0;
}
