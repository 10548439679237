@import 'src/shared/styles/variables';

.document__wrapper {
  display: flex;
  justify-content: space-between;
  align-content: center;

  &:not(:last-child) {
    margin-bottom: 31px;
  }
}

.document-title {
  margin: 0;
}

.document-subtitle {
  font-size: 10px;
  line-height: 13px;
  color: $c-purple-2;
}

.document-download-btn {
  font-size: 10px;

  > svg {
    margin-right: 9px;

    > path {
      fill: $c-blue;
    }
  }
}
