@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.company-dropdown-btn__wrapper {
  .dropdown-button__wrapper {
    padding-top: 2px;
    margin-right: 26px;

    ~ .dropdown-button__menu {
      transform: translate(calc(15px - 50%), 65px) !important;
    }

    .company-logo {
      @include size(34px);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $company-logo;
      border-radius: 50%;
      transition: border 0.3s;

      img {
        @include size(28px);
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &:hover {
      .company-logo {
        border: 1.5px solid $active-company-logo;
      }
    }
  }

  .dropdown-button__menu {
    .dropdown-menu__icon {
      margin-right: 11px;

      &.logout-icon {
        margin-right: 9px;

        svg {
          transform: translateY(2px);
        }
      }
    }
  }
}
