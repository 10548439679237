@import '~bootstrap/scss/bootstrap-grid';

.settings-layout {
  display: flex;
  flex-direction: column;
  margin: 7px auto 0;
}

.settings-layout__title-block {
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 35px;
}

.settings-layout__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}
