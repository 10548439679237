.unlock-account__form {
  margin-bottom: 76px;
}

.entry-title__wrapper {
  align-items: center;

  > div {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
}
