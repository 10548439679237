@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.balance {
  position: relative;
  padding: 62px 27px 24px 29px;
  border-radius: 10px;
  background-color: $c-white;
  max-height: 220px;
  border: 1px solid $c-block-border;

  @include media-breakpoint-down(sm) {
    margin-bottom: 13px;
  }
}

.balance__title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 9px;
}

.balance__title--calendar-view {
  font-size: 13px;
  margin-bottom: 3px;
}

.balance__amount {
  font-size: 30px;
  font-weight: 500;

  &:first-letter {
    color: $c-green;
  }
}

hr {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.5px solid $c-gray-8;
  margin-bottom: 19px;
  margin-top: 20px;
}

.balance__menu {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
}

.balance__difference {
  font-size: 12px;
  color: $account-projected-balance-color;
}

.balance__difference--negative {
  color: $account-projected-balance-color-negative;
}
