.entry-title__wrapper {
  align-items: center;
  justify-content: center;

  > div {
    align-items: center;
    max-width: 369px;
    text-align: center;
  }
}
