@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.tooltip {
  display: flex;
  align-items: center;
  z-index: $z-tooltip;

  &.show {
    opacity: 1;
  }

  > .tooltip-inner {
    background: $c-tooltip-bg;
    border-radius: 3px;
    width: 115px;
    font-size: 10px;
    line-height: 13px;
    color: $c-tooltip;
    padding: 4px;
  }

  > .arrow {
    display: none;
  }

  &.invoice-tooltip {
    > .tooltip-inner {
      transform: translateX(4px);
    }
  }

  &.short-list-tooltip {
    > .tooltip-inner {
      transform: translate(4px, 1px);
    }
  }

  &.small-short-list-tooltip {
    > .tooltip-inner {
      transform: translate(0, 1px);
      width: 58px;
    }
  }
}
