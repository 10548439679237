@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.avatar__img {
  @include size(30px);
  border-radius: 4px;
  box-sizing: border-box;
  object-fit: cover;
}

.avatar__img--medium-s {
  @include size(46px);
  border-radius: 10px;
}

.avatar__img--big {
  @include size(69px);
  border-radius: 10px;
}
