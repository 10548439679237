@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.form-errors-wrapper {
  color: $error-color;
  font-size: 10px;
  padding-top: 3px;
  font-weight: 400;
  font-family: $font-complementary;

  @include media-breakpoint-down(xs) {
    font-size: 9px;
  }
}
