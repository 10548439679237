@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.change-avatar__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
}

.logo__wrapper {
  border: 1px solid $c-light-blue;
  border-radius: 10px;
  height: 56px;
  width: 56px;
  margin-left: 6px;

  @include media-breakpoint-down(xs) {
    height: 50px;
    width: 50px;
  }
}

.company__logo {
  border: 4px solid $c-white;
  height: 54px;
  width: 54px;
  margin-top: 1px;

  @include media-breakpoint-down(xs) {
    height: 48px;
    width: 48px;
  }
}

.remove-button {
  font: inherit;
  background-color: transparent;
  border: none;
  color: $c-red;
  padding: 0;
  margin: 4px 1px 0 0;

  @include media-breakpoint-down(xs) {
    font-size: 10px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    border: none !important;
    color: $c-red !important;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
