@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.short-list__modal {
  padding: 22px 25px 28px 24px;
  overflow-y: hidden;
  max-height: 525px;
}

.short-list__wrapper {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: 322px;
  }
}

.short-list-link-company {
  font-size: 10px;
  line-height: 20px;
  white-space: nowrap;
  margin-right: 0;

  @include media-breakpoint-up(sm) {
    margin-right: 13px;
  }
}

.short-list-companies__wrapper {
  overflow-y: auto;
  height: 426px;

  scrollbar-width: thin;
  scrollbar-color: $c-scrollbar-thumb $c-scrollbar-track-bg;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: $c-scrollbar-track-bg;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $c-scrollbar-thumb;
  }
}

.short-list__item {
  padding: 0 0 13.84px 0 !important;
  &:last-child {
    padding: 0 !important;
  }
}

.close-button {
  top: 25px;
  right: 26px;
}
