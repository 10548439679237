@import 'src/shared/styles/variables';

.field-wrapper {
  max-width: 100%;
}

.field-input {
  width: 100%;
  resize: none;
  text-align: justify;
  min-height: 70px;
  overflow-wrap: break-word;
}

.field-textarea {
  background-color: transparent !important;
  border: solid 1px $input-border;
  border-radius: 2px;
  font-size: 12px;
  padding: 16px;
  line-height: 1.3;
  text-align: start;
}

.textarea__container {
  position: relative;
}

.letters-counter {
  padding-top: 4px;
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  color: $textarea-letters-counter;
  position: absolute;
  bottom: 15px;
  right: 30px;
}

.invalid {
  border-color: $input-border-error;
}
