@import 'variables';

@font-face {
  font-family: #{$font-euclid};
  src: url('../../assets/fonts/ESRebondGrotesque-Regular.woff2') format('woff2'),
    url('../../assets/fonts/ESRebondGrotesque-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: #{$font-euclid};
  src: url('../../assets/fonts/ESRebondGrotesque-Medium.woff2') format('woff2'),
    url('../../assets/fonts/ESRebondGrotesque-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: #{$font-euclid};
  src: url('../../assets/fonts/ESRebondGrotesque-Bold.woff2') format('woff2'),
    url('../../assets/fonts/ESRebondGrotesque-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: #{$font-inter};
  src: url('../../assets/fonts/ABCWhyte-Regular.woff2') format('woff2'),
    url('../../assets/fonts/ABCWhyte-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}
