@import 'src/shared/styles/variables';

.button-container {
  position: relative;
}

.shortlist-button {
  -webkit-tap-highlight-color: transparent;
  background: $c-short-list-btn-bg;
  border-radius: 2px;
  border: 1px solid $c-short-list-btn-bg;
  padding: 3px 2px 0;
  height: 20px;

  &.active {
    border: 1px solid $c-blue;

    > svg {
      fill: $c-blue;
    }
  }

  &.can-remove {
    &:hover {
      border: 1px solid $c-short-list-active-btn-hover;
      background-color: rgba($c-short-list-active-btn-hover, 0.2);

      > svg {
        fill: $c-short-list-active-btn-hover;
      }
    }
  }

  > svg {
    fill: $c-short-list-btn-icon;
  }

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
  }
}
