@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.column {
  margin-bottom: 22px;
  padding-left: 21px;

  &:nth-last-child(2) {
    margin-bottom: 25px;
  }

  &:last-child {
    margin-bottom: 20px;
  }

  .label {
    margin-right: 16px;
    color: $c-dark-blue;
    margin-bottom: 5px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0;
  }

  .value {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    svg {
      margin-left: 5px;
    }
  }
}

.payment-review__wrapper {
  padding-top: 9px;
}

.date-field-value__wrapper {
  display: flex;

  > div {
    width: auto !important;
  }
}

.edit-pen {
  fill: $c-blue;
  cursor: pointer;
}

.schedule-edit-pen {
  fill: $c-blue;
  cursor: pointer;
  transform: translate(5px, 3px);

  &.edit-pen--disabled {
    cursor: not-allowed;
    pointer-events: none;
    fill: $c-light-blue-3;
  }
}

.edit-date-pen {
  fill: $c-blue;
  transform: translateX(3px);
  cursor: pointer;
}

.payment-review-footer {
  padding-top: 19px;
  margin: 17px 17px 0 0;
}
