@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.transaction-card-footer__actions-wrapper {
  .dropdown-menu {
    padding: 7px 6px 3px 3px;
  }

  .dropdown-button__menu-item {
    padding-left: 11px;
    margin-bottom: 2px;

    svg {
      margin-right: 11px;

      &.trash-icon {
        transform: translateY(1px);
      }
    }

    &:last-child {
      margin-top: 2px;
      margin-bottom: 0;
      padding-top: 2px;
      padding-bottom: 5px;
    }
  }

  .transaction-card-footer__actions-btn {
    fill: $c-transaction-card-actions-icon;
    margin-top: 3px;
    padding: 8px 13px 4px 14px;
    border-radius: 2px;

    &:hover {
      background: $c-transaction-card-actions-bg;
    }
  }

  .show.dropup {
    @include media-breakpoint-down(md) {
      .dropdown-button__menu {
        left: -35% !important;

        &::before {
          left: 70% !important;
        }
      }
    }

    .transaction-card-footer__actions-btn {
      background: $c-transaction-card-actions-bg;
    }
  }
}
