@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.suggestion__list {
  margin-top: 8px;
  padding: 12px 3px 6px;
  position: absolute;
  box-shadow: 0 0 15px 0 $dropdown-menu-shadow;
  border: solid 1px $dropdown-menu-border;
  background-color: $dropdown-menu-bg;
  border-radius: 4px;
  color: $input-color;
  z-index: $z-autocomplete-input;
}

.suggestion__item {
  cursor: pointer;
  font-size: 12px;
  padding: 2px 12px;
  border-radius: 4px;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 3px;
  }
}

.suggestion__item--active {
  background-color: $dropdown-menu-item-bg-active;
}

.google-logo {
  text-align: right;
  padding-right: 6px;
}

.google-logo__img {
  height: 12px;
}
