@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.fields-container {
  min-height: 228px;
  margin-bottom: 33px;
}

.contact-form__input {
  padding: 14px 16px;
}

//Business Name input styles
.company-list__container {
  width: 100%;
  margin-top: 10px;
  padding: 10px 13px 0 0;
  position: absolute;
  right: 0;
  top: 35px;
  background-color: $c-company-list-bg;
  border-radius: 10px;
  display: none;
  flex-direction: column;
  text-align: initial;
  z-index: $z-search-company-list;
  box-shadow: $c-add-bill-company-list-shadow;

  @include media-breakpoint-down(md) {
    width: 100%;
    top: 55px;
  }
}

.company-list__container--active {
  display: flex;
  justify-content: space-between;
}

.scroll--hidden {
  padding-right: 0;

  .entity-short-card {
    margin-right: 0;
  }
}

.company-list__container--hidden {
  height: 48px;
  justify-content: center;
  margin-top: 5px;
}

.scrollbar {
  max-height: 290px;
  margin-bottom: 20px;
}

.scrollbar--hidden {
  display: none;
}

.new-vendor {
  width: 75px;
  height: 18px;
  position: absolute;
  right: 12px;
  top: 9px;
  background: $c-add-bill-new-vendor-bg;
  font-size: 10px;
  color: $c-add-bill-new-vendor;
  margin: 0;
  padding: 4px 6px 4px 9px;
  border-radius: 4px;
  text-align: center;
}

.new-vendor--hidden {
  display: none;
}

.business-input {
  padding: 3px 0 6px 37px;
  height: 35px;
  border-radius: 5px;
  border-color: $input-border;

  &::placeholder {
    font-size: 12px;
  }
}

.business-input__new-vendor {
  padding-right: 90px;
}

.business-input__icon {
  @include size(14px);
  left: 15px;
  top: 10px;
  transform: none;
}

.new-business__wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $c-company-list-new-business;
  padding: 5px 0 15px 27px;
}

.input--disabled {
  color: $c-add-bill-email-input-disabled;
  opacity: 0.5;
}
