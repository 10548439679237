@import 'src/shared/styles/variables';

.fields-container {
  margin-bottom: 16px;

  label {
    margin-bottom: 8px;
  }
}

.form,
.content-block {
  border: 1px solid $c-dark-grey;
  border-radius: 10px;
  padding: 32px;
}

.button {
  display: block;
  margin-right: 0;
  margin-left: auto;
  padding: 5px 10px;
  width: 104px;
}

.save-button {
  width: 78px;
  padding: 4px 6px;
  min-width: auto;
}
