@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

button.tabs__item {
  @include button-reset;
  position: relative;
  flex: 0 0 auto;
  margin-right: 29px;
  text-transform: capitalize;
  color: $c-accounting-tab;
  text-align: left;
  cursor: pointer;
  font-weight: 500;
  width: 85px;
  white-space: nowrap;
  padding: 0 0 13px 0;

  @include media-breakpoint-down(md) {
    width: 65px;
  }

  @include media-breakpoint-down(sm) {
    transform: translateY(1px);
  }

  &:nth-child(2) {
    margin-right: 36px;
  }

  &:last-child {
    margin-right: 0;
    margin-left: 2px;
  }

  &::after {
    display: block;
    content: '';
    height: 2px;
    border-radius: 2px;
    width: 100%;
    background-color: $c-blue;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all $t-default;
  }

  &:hover,
  &:focus {
    background: transparent !important;
    color: $c-accounting-tab !important;
  }

  &[disabled] {
    background: transparent !important;
  }

  &.tabs__item--active {
    color: $c-blue !important;
    pointer-events: none !important;

    &:after {
      opacity: 1;
    }
  }
}
