@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.title__wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 17.63px;

  font-size: 12px;

  border-bottom: 0.5px solid $c-gray-8;
}

.title__info-wrapper {
  display: flex;
  align-items: center;
}

.title {
  line-height: 15px;
  margin-left: 9.7px;
}

.title-steps {
  margin-left: auto;
  padding-top: 2px;
  min-width: 60px;
  color: $c-blue;

  @include media-breakpoint-down(xs) {
    padding-top: 0;
  }
}
