@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.transaction-history__controls {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $c-accounting-tabs-border;
  margin-bottom: 22px;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    border-bottom: 0;
    margin-bottom: 0;
  }
}
