@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.invoice-owner {
  padding: 0 10px;

  @include media-breakpoint-up(sm) {
    padding: 0 21px;
  }
}

.invoice-owner__logo {
  margin-right: 14px;
  @include size(34px);

  & > img {
    @include size(34px);
  }
}

.invoice-owner__vendor-name {
  display: flex;
  max-width: 338px;
  margin-bottom: 2px;
}

.invoice-owner__name {
  font-size: 16px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 253px;
  white-space: nowrap;

  @include media-breakpoint-down(xs) {
    max-width: 128px;
  }
}

.invoice-owner__new-vendor {
  font-size: 10px;
  line-height: 13px;
  color: $c-light-gray-11;
  background: rgba($c-light-blue, 0.5);
  border-radius: 4px;
  padding: 3px 4px 2px;
  margin-left: 10px;
}

.invoice-owner__email {
  font-size: 12px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 338px;

  @include media-breakpoint-down(xs) {
    max-width: 150px;
  }
}

.invoicing-number {
  width: 90px;
  height: 30px;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  color: $c-gray-1;
  margin-top: 2px;
  margin-right: -2px;
}
