@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $c-entry-subtitle;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
  font-family: $font-complementary;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
  }
}

.subtitle__text {
  margin: 0;
}

.subtitle__email {
  margin: 0 4px;
  font-weight: 700;
}
