.counterparty-field__logo {
  margin-right: 11px;

  .entity-logo {
    height: 30px;
  }

  .entity-logo__icon {
    bottom: -5px;
  }
}

.entity-short-card.transaction-history-company-card {
  padding: 0;
  align-items: flex-start;

  .entity-short-card__info-wrapper {
    max-width: calc(100% - 50px);
    padding-top: 2px;
  }

  .entity-short-card__info,
  .entity-short-card__help-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  .entity-short-card__info {
    line-height: 15px;
  }

  &.transaction-history-transfer-card {
    .entity-short-card__info-wrapper {
      max-width: calc(100% - 35px);
    }
  }
}
