@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.invoice-history {
  height: 274px;
  padding-right: 22px;

  .entity-short-card__info {
    max-height: 16px;
    -webkit-line-clamp: 1;
    max-width: 200px;
    word-wrap: break-word;
  }

  .invoice-history__amount {
    flex: 0 0 auto;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    border-radius: 4px;
    padding: 4px 6px 2px;
    min-width: 50px;
    text-align: center;
    background: $c-payable-amount-bg;
    color: $c-payable-amount-text;
    margin-left: 16px;
    align-self: center;
    transform: translateY(1px);

    &.receivable-invoice {
      background: $c-receivable-amount-bg;
      color: $c-receivable-amount-text;
    }
  }

  &.company__history {
    min-height: unset;
    height: 250px;
    padding-top: 21px;
    padding-bottom: 45px;

    .collapse-button__wrapper {
      height: 75px;
      padding-bottom: 17px;
      background: linear-gradient(180deg, rgba($c-collapse-bg, 0) -19.79%, $c-collapse-bg 45px);
    }
  }
}
