@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.header {
  width: 100%;

  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: center;
    padding-left: 0;
  }
}
