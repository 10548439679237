@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.add-bill__return-btn {
  position: absolute;
  top: 0;
  left: -13px;

  @include media-breakpoint-up(sm) {
    top: 24px;
    left: -13px;
  }
}

.add-bill-page__wrapper {
  padding-top: 7px;
}

.add-bill-review-page__wrapper {
  width: 100%;
}
