@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.payment-method__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $c-setting-payment-method-item;
  padding: 17px 0 24px 7px;
  border-radius: 10px;

  @include media-breakpoint-up(md) {
    padding: 17px 36px 24px 22px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.confirm-delete-title {
  margin-top: 11px;
  margin-bottom: 27px;
  max-width: 220px;
}
