@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.button {
  width: 100%;
  padding: 12px 20px 11px 19px;
  border-radius: 2px;
  outline: none;
  background: $c-light-gray;
  border: none;
  margin-bottom: 83px;
  margin-left: 3px;
  font-family: inherit;

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.active {
    background: $c-active-entity-card-bg;
  }

  &__info {
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    &-message {
      font-weight: 600;
      color: $c-entity-card-text;
      margin: 0;
    }
  }

  &__help-text {
    margin: 0;
    color: $c-entity-card-help-text;
    transform: translateY(1px);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }

  &__additional-info {
    font-size: 12px;
    font-weight: 500 !important;
    line-height: 16px;
    text-align: right;
    color: $c-blue;
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-family: inherit;
    padding: 0;

    &:disabled {
      cursor: not-allowed;
    }

    svg {
      margin-right: 10px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 8px;
    }
  }
}

.icon-warning {
  width: 10px;
  height: 10px;
  margin-left: 9px;
}

.icon-add-payment {
  width: 10px;
  height: 10px;
}

.icon-edit-pen {
  margin-right: 7px;
  fill: $c-blue;
}

.field {
  padding: 12px 12px 11px 22px;
  margin-bottom: 83px;

  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 3px;

    &-text {
      color: $c-dark-blue;
    }
  }

  &__value {
    font-size: 12px;
    color: $c-gray-1;
  }

  &__button {
    font-size: 12px;
    color: $c-blue;
    outline: none;
    border: none;
    background: none;
    font-family: inherit;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    padding: 0;
    margin-right: 9px;

    &:disabled {
      cursor: not-allowed;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 8px;
    }
  }
}

.date-action {
  margin-bottom: 17px;
}
