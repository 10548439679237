@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.invoice-status {
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  &.scheduled {
    color: $c-scheduled-invoice;
  }

  &.completed {
    color: $c-completed-invoice;
  }

  &.coming-up {
    color: $c-coming-up-invoice;
  }

  &.overdue {
    color: $c-overdue-invoice;
  }
}
