@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.history-duration-filter {
  display: flex;
  align-items: center;
  transform: translateY(-2px);
  font-weight: 500;

  @include media-breakpoint-down(sm) {
    margin: 22px 0;
    padding-left: 5px;
  }
}

.history-duration-filter__input {
  margin-left: 14px;
  margin-bottom: 0;
  width: 115px;
  min-width: auto;
  font-weight: 400;
}
