@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.tabs {
  display: flex;
  transform: translateY(1px);

  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid $c-accounting-tabs-border;
    flex: 0 0 100%;
  }
}
