@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.field-wrapper {
  margin-bottom: 16px;
  width: 100%;
  max-width: 370px;

  @include media-breakpoint-down(md) {
    margin-bottom: 25px;
  }

  &:last-child {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.field-wrapper--xs {
  max-width: 195px;
}

.field-wrapper--m {
  max-width: 282px;
}

.field-wrapper--l {
  max-width: 400px;
}

.field-wrapper--xl {
  max-width: 415px;
}

.field-label {
  color: $c-entry-input-label;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  margin: 0 0 8px 0;
  display: block;

  @include media-breakpoint-down(md) {
    font-size: 15px;
  }
}

.field-input {
  background-color: $input-bg;
  border: 1px solid $input-border;
  border-radius: 5px;
  color: $input-color;
  font-family: $font-complementary;
  font-size: 13px;
  outline: none;
  padding: 9px 11px;
  width: 100%;
  height: 35px;
  line-height: 1.2;
  transition: background-color $t-input-color, border-color $t-input-color;

  &:hover {
    background-color: $input-bg-hover;
  }

  &:focus {
    border-color: $input-border-focus;
    background-color: $input-bg-focus;
  }

  &::placeholder {
    color: $input-placeholder;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &.field-input--invalid {
    border-color: $input-border-error;
  }
}

.form-errors-wrapper {
  color: $input-error;
  font-size: 12px;
  padding-top: 3px;

  @include media-breakpoint-down(md) {
    font-size: 11px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 9px;
  }
}
