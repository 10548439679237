@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.transaction-card__wrapper {
  background: $c-transaction-card-bg;
  box-shadow: 0 4px 45px $c-transaction-card-shadow;
  border-radius: 10px;
  padding: 21px 22px 18px 32px;
  min-height: 454px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 5vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 370px;
    width: calc(100% - 40px);
    height: 100%;
    max-height: 90vh;
    z-index: $z-transaction-card;
    padding-left: 22px;
    padding-right: 20px;
  }

  &::before {
    @include size(22px);
    content: '';
    display: block;
    position: absolute;
    background: $c-transaction-card-bg;
    transform: rotate(45deg) translateY(-50%);
    top: 50%;
    left: -12px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.transaction-card__entity-wrapper {
  display: flex;
  align-items: flex-start;
}

.transaction-card__entity-name {
  font-size: 16px;
  line-height: 19px;
  color: $c-transaction-card-entity-name;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.transaction-card__entity-subname {
  margin-right: 5px;
}

.transaction-card__entity-info {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  padding-top: 6px;
  font-size: 16px;
  line-height: 19px;
}

.transaction-card__entity-verification-label {
  flex: 0 0 auto;
}

.transaction-card-header__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 0.5px solid $c-transaction-card-border;
  padding-bottom: 15px;
  margin-bottom: 17px;

  .transaction-card-header__close-btn {
    padding: 1px 3px;
  }
}

.transaction-card-header__breadcrumbs {
  display: flex;
  align-items: center;

  .transaction-card-header__breadcrumbs-item {
    font-size: 10px;
    line-height: 15px;
    padding: 0;
    margin-right: 10px;
    font-weight: 500;

    &:not(:last-child) {
      color: $c-transaction-card-breadcrumbs;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background: $c-transaction-card-breadcrumbs;
        left: 0;
        bottom: 0;
        transform: translateY(0.6px);
      }

      &::after {
        content: '>';
        display: block;
        right: -7px;
        top: 0;
        position: absolute;
      }

      &:hover {
        color: $c-transaction-card-breadcrumbs;
      }
    }

    &:last-child {
      color: $c-transaction-card-active-breadcrumbs;
    }
  }
}

.transaction-card-header {
  overflow: hidden;
  text-overflow: ellipsis;
}

.transaction-card-header__amount {
  flex: 0 0 auto;
  align-self: flex-start;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-top: 6px;
  margin: 0 0 0 27px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;

  div:first-letter {
    color: $c-green;
  }
}

.transaction-card__row-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
