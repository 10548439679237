.layout__main {
  flex: 1 1 auto;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.container {
  width: auto;
}
