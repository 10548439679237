@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.transaction-history-table__wrapper {
  background: $c-transaction-history-bg;
  border-radius: 10px;
  padding: 23px 12px 15px 18px;
  height: 454px;
  border: 1px solid $c-block-border;

  @include media-breakpoint-down(sm) {
    padding-right: 22px;
    padding-left: 13px;
    margin-bottom: 25px;
  }

  .transaction-history-table__amount-field {
    padding-right: 10px;
  }

  .transaction-history-table__message-field {
    max-width: 135px;
  }

  .transaction-history-table__counterparty-field {
    min-height: 36px;
  }

  .transaction-history-table__due-date-field {
    overflow: initial;
  }
}

.transaction-history-search__wrapper {
  padding-left: 13px;
}

.transaction-history-table__row {
  cursor: pointer;
  padding-bottom: 4px;

  > div {
    padding-bottom: 5px;
  }
}
