.subtitle {
  margin: 0 0 24px;
}

.subtitle--success {
  margin-bottom: 109px;
}

.datepicker__wrapper {
  max-width: 178px;
}
