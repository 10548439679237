@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.btn {
  background: $button-color;
  border: 1px solid $button-color;
  border-radius: 20px;
  min-width: 89px;
  padding: 4px 13px;
  font-size: 12px;
  line-height: 20px;
  font-family: $font;
  outline: none;
  color: $c-white;
  cursor: pointer;
  transition: all 0.3s;
  min-height: 30px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:not(.btn--disabled):hover,
  &:not(.btn--disabled):active {
    color: $c-white;
    text-decoration: none;
    background: $button-hover-color;
    border-color: $button-hover-color;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &.btn--disabled {
    border-color: $c-light-blue-3;
    background: $c-light-blue-3;
    border-radius: 20px;
    box-shadow: none;
    cursor: not-allowed;
  }

  .btn__icon {
    margin-left: 11px;
    display: inline-flex;
    align-items: center;

    svg {
      fill: $c-white;
    }
  }

  &.btn--primary {
    box-shadow: 0 4px 20px $shadow-color;

    &:not(.btn--disabled):hover,
    &:not(.btn--disabled):active {
      box-shadow: 0 4px 20px $shadow-color;
    }
  }

  &.btn--danger {
    background: $c-danger-button;
    border-color: $c-danger-button;

    &:not(.btn--disabled):hover,
    &:not(.btn--disabled):active {
      background: $c-danger-button;
      border-color: $c-danger-button;
    }
  }

  &.btn--outline {
    border: 1px solid $button-color;
    color: $button-color;
    background-color: transparent;

    &:not(.btn--disabled):hover,
    &:not(.btn--disabled):focus,
    &:not(.btn--disabled):active {
      background-color: transparent;
      border-color: $button-hover-color;
      color: $button-hover-color;
    }

    &.btn--disabled {
      background: transparent;
      border-color: $c-light-blue-3;
      color: $c-light-blue-3;
    }

    &.btn--danger {
      color: $c-danger-button;
      border-color: $c-danger-button;

      &:not(.btn--disabled):hover,
      &:not(.btn--disabled):active {
        color: $c-danger-button;
        border-color: $c-danger-button;
      }
    }
  }

  &.btn--link {
    border: 0;
    padding: 5px 10px;
    background: transparent;
    color: $accent-color;
    min-height: auto;
    min-width: auto;

    &:not(.btn--disabled):hover,
    &:not(.btn--disabled):focus,
    &:not(.btn--disabled):active {
      background: transparent;
      color: $accent-color;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &.btn--disabled {
      background: transparent;
      color: $c-gray;
    }
  }

  &.btn--danger-link {
    color: $c-danger-button;
  }
}
