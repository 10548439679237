@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.upload-button {
  background-color: transparent;
  border: none;
  font: inherit;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 2px 12px 0 0;

  &:disabled {
    cursor: not-allowed;
  }
}

.upload-button__text {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 7px;
  color: $accent-color;

  @include media-breakpoint-down(xs) {
    font-size: 10px;
  }
}
