@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.notes__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(md) {
    align-items: start;
  }
}

.notes {
  font-family: $font-complementary;
  font-size: 12px;
  line-height: 15px;
  max-width: 273px;
  margin: 32px 0 8px;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: start;
  }

  &:last-child {
    margin: 0;
  }
}

.actions {
  color: $accent-color;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  font-size: 12px;
  line-height: 15px;
  font-family: $font-complementary;
  padding: 0;
}
