@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.company-search {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-search__container {
  width: 100%;
  position: relative;
}

.company-search__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  background-color: $c-search-bg;
  border-radius: 10px;
  text-align: center;
  height: 130px;
  position: relative;
}

.company-search__title {
  color: $c-search-title;
  line-height: 35px;
  font-size: 25px;
  font-weight: 400;
  margin: 0;
}

.search-input {
  padding: 15px 35px 15px 66px;
}

.search-input__container {
  position: absolute;
  top: 105px;
  min-width: 300px;
  width: 39%;
}

.company-list__container {
  width: 100%;
  margin: 10px auto 0;
  padding-right: 13px;
  z-index: $z-search-company-list;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: $c-company-list-bg;
  border-radius: 10px;
  display: none;
  flex-direction: column;
  text-align: initial;
  border: 1px solid $c-dark-grey-1;
}

.company-list__container--active {
  display: flex;
}

.scroll--hidden {
  padding-right: 0;

  .entity-short-card {
    margin-right: 0;
  }

  .new-business__wrapper {
    margin-right: 15px;
  }
}

.scrollbar {
  border-bottom: 0.5 solid;
  margin-bottom: 10px;
}

.contact-list__title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  padding: 21px 0 0 24px;
  margin-bottom: 10px;
}

.new-business__wrapper {
  padding: 14px 0 22px 4px;
  margin: 2px 2px 0 23px;
  border-top: 0.5px solid $c-company-list-border;
}

.company-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 92px 0 100px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}

.company-actions__wrapper {
  width: 100%;
  justify-content: center;
  margin: 18px 0 50px;
  pointer-events: none;
  display: flex;

  > button {
    cursor: pointer;
    pointer-events: auto;
    transition: opacity $t-default;
    border-radius: 10px;
  }

  &:hover > button {
    opacity: 0.2;

    &:hover {
      opacity: 1;
      box-shadow: 0 4px 45px $c-light-gray-5;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.company-actions__wrapper--blur {
  opacity: 0.2;

  > button {
    pointer-events: none;
  }
}

.company-actions__title {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $c-search-action-title;
  padding-top: 9px;
  padding-right: 1px;
  margin: 0;
}

.company-actions__popup {
  height: 100%;
  width: 100%;
  position: absolute;
}
