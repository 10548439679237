@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.fields-wrapper {
  margin: 24px 0;
}

.field-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 22px;

  @include media-breakpoint-down(xs) {
    align-items: normal;
    flex-direction: column;
  }
}

.input-wrapper {
  flex-basis: 67%;
}

.field__input {
  @include media-breakpoint-down(xs) {
    height: 30px;
    font-size: 11px;
  }
}

.input-label {
  @include media-breakpoint-down(md) {
    font-size: 13px;
  }
}

.buttons__wrapper {
  margin-top: 24px;
}
