@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.modal {
  padding: 27px 32px 33px 33px;
  max-width: 375px;
  line-height: 1.3;
  width: 85vw;
  min-height: 258px;
  box-sizing: border-box;
}

.title {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 20px;
}

.subtitle {
  margin: 0 0 27px;
  line-height: 1.3;
}

.buttons__wrapper {
  margin-top: 29px;
}

.cancel-button {
  margin-right: 12px;
}
