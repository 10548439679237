@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.datepicker {
  position: relative;
  text-align: left;
  width: 100%;
  border: 0.8px solid $c-datepicker-border !important;
  justify-content: flex-start;
  border-radius: 5px !important;
  max-width: initial;
  color: $c-datepicker !important;

  &.datepicker--empty {
    color: $c-datepicker-placeholder !important;

    .datepicker__control-icon {
      fill: $c-light-blue;
    }
  }
}

.datepicker__control-icon {
  @include size(15px);
  position: absolute;
  right: 12px;
  top: 8px;
  fill: $c-datepicker-active;
}
