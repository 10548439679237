@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.beneficial-owner__container {
  background-color: $c-light-gray-12;
  border-radius: 10px;
  display: flex;
  padding: 15px 18px 17px 21px;
  position: relative;
  margin-bottom: 16px;
  align-items: center;
}

.actions {
  display: flex;
  margin: 0 7px 2px auto;
}

.name {
  font-size: 12px;
  line-height: 16px;
  max-width: 124px;
  align-self: flex-start;
  font-weight: 500;
}

.icon-container {
  margin-right: 21px;
  transform: translateY(2px);
}

.icon {
  cursor: pointer;
  border: none;
  background-color: inherit;
  outline: none;
  transform: translateY(2px);
  padding: 0;
  margin: 0;
  min-width: fit-content;

  &:hover,
  &:focus {
    background: transparent !important;
    color: transparent !important;
    border-color: transparent !important;
  }

  svg {
    fill: $c-black !important;
  }
}

.edit-icon {
  fill: $c-black;
  margin-right: 12px;

  svg {
    @include size(15px);
  }

  &:hover,
  &:focus {
    fill: $c-dark-blue !important;
  }
}

.delete-icon {
  fill: $c-red;

  svg {
    @include size(16px);
  }
}
