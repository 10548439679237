@import '~bootstrap/scss/bootstrap-grid';

.receivable-container {
  @include media-breakpoint-up(xs) {
    margin-bottom: 18px;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 18px;
  }
}
