@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.new-business__wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $c-company-list-new-business;
  padding: 0 0 15px 24px;
}

.new-business__plus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 7px;
  height: 7px;
  margin-right: 8px;

  &::after,
  &::before {
    content: '';
    position: absolute;
    background: $c-company-list-new-business;
  }

  &::before {
    width: 1px;
    height: 100%;
  }

  &::after {
    width: 100%;
    height: 1px;
  }
}

.new-business__title {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  max-width: 90%;
  display: flex;
  white-space: nowrap;
}

.new-business__name {
  font-size: 12px;
  font-weight: 700;
  margin: 0 3px;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}
