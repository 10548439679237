@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.notification-button {
  position: relative;
  margin: 0 29px;
  transform: translateY(1px);
}

.notification-button__icon {
  transform: rotate(12deg);
  fill: $c-gray-1;
}

.notification-button__counter {
  position: absolute;
  height: 8px;
  width: 8px;
  background: $c-dark-red;
  color: $c-white;
  border-radius: 50%;
  bottom: -1px;
  right: -3px;
  font-size: 4px;
  line-height: 5px;

  .notification-button__counter-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
