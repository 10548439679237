@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.form-column {
  display: flex;
  flex-direction: column;
  min-width: 223px;
}

.password-field {
  margin-bottom: 3px !important;
}

.field__content-error {
  font-size: 10px;
  font-weight: 300;
  color: $c-error-field;
  padding-top: 3px;
  margin-bottom: 32px;
  font-family: $font-complementary;
}

.attempts-error {
  margin: 8px 0 0;
  padding: 0;
}
