.business__container {
  max-width: calc(100% - 50px);
}

.business__message,
.business__help-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
