@import 'src/shared/styles/variables';

.button-container {
  position: relative;
}

.edit-button {
  -webkit-tap-highlight-color: transparent;
  background: $c-short-list-btn-bg;
  border-radius: 2px;
  border: 1px solid $c-short-list-btn-bg;
  padding: 3px 2px 0;
  height: 20px;

  > svg {
    fill: $c-short-list-btn-icon;
  }

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
  }
}
