@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.side-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $c-dark-grey-1;
  background-color: $c-white;
  border-radius: 10px;
  padding: 7px;

  width: 100%;
  margin: 0 0 20px;
  height: auto;

  & > div {
    &:last-child {
      border-bottom: 1px solid $c-light-blue-4;
    }
  }

  @include media-breakpoint-up(md) {
    width: auto;
    margin: 0 10px 0 0;
  }
}

.side-nav__link {
  display: flex;
  align-items: center;
  width: 159px;
  height: 39px;
  color: $c-black;
  font-size: 10px;
  border-radius: 4px;
  padding: 7px 0 9px 17px;
  transition: color 0.3s;

  &:hover {
    color: $c-black;
  }
}

.side-nav__link--active {
  width: 100%;
  color: $c-black !important;
  background-color: $settings-menu-active-bg;

  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

.side-nav__icon {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 14px;
  height: 14px;

  svg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

      > path {
        fill: $c-black;
    }
  }
}
