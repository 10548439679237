@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.payment-method-info__wrapper {
  display: flex;

  & > img {
    border-radius: 10px;
    margin-right: 20px;
  }
}

.details-bank__wrapper {
  padding-top: 4px;
}

.bank-name__wrapper {
  margin-bottom: 10px;
}

.bank-name {
  margin-right: 20px;
  font-size: 12px;
  line-height: 16px;
}

.bank-status {
  font-size: 10px;
  line-height: 13px;
  white-space: nowrap;
  color: $c-light-gray-11;
  margin-right: 4px;
  border-radius: 4px;
  background-color: $c-setting-payment-method-status-account;
  padding: 3px 5px 2px 7px;
}

.status-bank-mobile__wrapper {
  margin-bottom: 10px;

  @include media-breakpoint-up(md) {
    display: inline-block;
    margin-bottom: 0;
  }
}

.bank-numbers {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
}
