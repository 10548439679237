@import '~bootstrap/scss/bootstrap-grid';
@import 'src/shared/styles/variables';

.footer {
  padding: 15px 0 38px;
  color: $text-footer-color;
  font-size: 10px;
  font-family: $font-complementary;
  font-weight: 400;
}
