@import 'src/shared/styles/variables';

.invoice-preparing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $c-light-gray-10;
  padding: 155px 0 270px;
}

.invoice-preparing__text {
  margin-bottom: 20px;
}
