@import 'src/shared/styles/variables';

.title {
  display: flex;
  padding-top: 2px;
}

.title__text {
  margin-left: 12px;
  font-size: 12px;
  color: $c-black;
}
